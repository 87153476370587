.link { stroke: #c1c1c1; stroke-opacity: .6; stroke-width: 1px; }
.a-chart-wrapper{
    /* display:flex; */
    
}


.node circle {
    pointer-events: all;
    stroke: #777;
    stroke-width: 1px;
  }


  div.tooltip-causal {
    position: absolute;
    background-color: white;
    max-width: 200px;
    height: auto;
    padding: 1px;
    border-style: solid;
    border: 1px solid #846c6c;
    border-radius: 6px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
    pointer-events: none;
    z-index: 1001;
} 
.svg-graph-container {
  transform:translate(10px,0px);
  min-height:510px;
  border:1px solid #ccc;
  margin:10px;
} 