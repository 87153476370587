.exp_run_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}
.exp_run_container > .exp_select,
.exp_run_container > .run_select {
  display: flex;
  flex: 1;
  margin: 5px;
}
.exp_run_container > .run_select > label {
 text-align: right;
 padding-right:10px;
}
.exp_run_container > .exp_select > label,
.exp_run_container > .run_select > label {
  width: 35%;
  padding-top: 10px;
}
.exp_run_container > .exp_select > .experiment-select,
.exp_run_container > .run_select > .experiment-select{
  width:100%;
}
.pipeline_rca {
  width: 40%;
  display: flex;
  margin: 10px 0px;
}
.pipeline_rca > label {
  width: 20%;
  padding-top: 10px;
  padding-right: 5px;
}
.rca_heading {
  font-size:14px;
  font-weight: 800;
  margin:20px 0px;
}
.run_rca_btn {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}
.rca_form {
  padding: 10px;
}

.rca_form > form > .form-group > #root {
  position: relative;
  flex-basis: inherit;
  border: none !important;
  width: 100%;
  margin-bottom: 0px !important;
}
.rca_form > form > .form-group > #root > .field-string {
  width: 45%;
  display: inline-flex;
  margin: 10px 5px;
}

.rca_form > form > .form-group > #root > .field-string > label {
  width: 30%;
  padding-top: 8px;
}
.rca_kpis{
  margin-bottom:20px;
}
.DS-dropdown{
  width:50%;
}
.predictive-params{
      flex: 1 1;
      min-height: 0px;
      max-height: 150px;
      overflow: auto;
}
.predictive-params > form > .form-group > fieldset{
  padding: 5px !important;
}
.hide-rca-options {
    display: none;
}
.show-rca-options{
    display: block;
}
.predictive_kpi_section_main{
  margin:10px;
}
#kpi_predictive[aria-expanded="true"]{
  min-height:330px;
  max-height: 0px;
  z-index:0;
}
.rca_impact_count{
  display: flex;
  width:35%;
  border-radius: 2px;
}
.rca_impact_count .rca_count{
  width:50%;
  background-color: #fff;
  margin: 5px 4px 5px 6px;
  border-radius: 2px;
  padding:5px;
  box-shadow:0 2px 5px rgba(0,0,0,0.25), 0 5px 2px rgba(0,0,0,0.22);
  text-align: center;
}
.rca_impact_count .impact_count{
  width: 50%;
    background-color: #fff;
    margin: 5px 5px 5px 2px;
    padding: 5px;
    border-radius: 2px;
    box-shadow:0 2px 5px rgba(0,0,0,0.25), 0 5px 2px rgba(0,0,0,0.22);
    text-align: center;
}
.flex_end{
  justify-content: flex-end;
}
.m-0{
  margin:0px !important;
}
.font_20{
  font-size: 20px !important;
}