@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.flexDisplay{ 
  display: flex; 
}

.fa {
  font-size: 12pt !important;
}
.body {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  margin: 0;
}

.content {
  background-color: #ffffff;
}

#main {
  display: flex;
  min-height: calc(100vh - 10vh);
  flex: 1 1;
}

#main > article {
  flex: 1 1;
}

#main > nav {
  flex: 0 0 20vw;
}

#main > nav {
  order: -1;
}

header,
footer,
aside {
  padding: 1em;
}

article {
  padding: 0em 1em;
}
header {
  background: #eeeeef !important; 
  display: flex !important;
}


.main-nav {
  margin-top: 22px;
  padding-left: 0px;
}

.logo a,
.main-nav a {
  padding: 10px 15px;
  text-transform: uppercase;
  display: block;
}

.nav {
  padding: 0px;
  text-decoration: none;
}

.logo a {
  color: white;
  padding: 2px 0px 0px 2px;
}
.logo a img {
  /* width:100%; */
  /* height:60px; */
}

.main-nav a {
  color: #8d939d;
  font-size: 0.99em;
}

.main-nav a:hover {
  color: #60dac2;
}

.main-nav .active {
  color: #60dac2;
  background: #0c1228;
  border-left: 3px solid #15d9c1;
}

header {
  /* padding-top: .5em;
    padding-bottom: .5em; */
  background-color: white;
}

.notification {
  background: grey;
  color: black;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.analyst-view,
.train-dt,
.train-ctrl {
  display: flex;
}

.analyst-view {
  flex-direction: row;
  justify-content: center;
}

.train-dt {
  flex-direction: column;
}

.train-ctrl {
  flex-direction: column;
  width: 100%;
}

.perf-panel {
  flex-direction: column;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2,
h3 {
  color: #34495e;
}

a {
  text-decoration: none;
}

.logo {
  margin: 0;
  font-size: 1.3em;
  padding: 0px 2px;
  width: 100%;
}

.set_col_dynamic {
  width: 100%;
  border: 1px solid #222222;
  min-height: 300px;
  max-height: 0px;
  overflow: auto;
  margin: 10px;
  background: lightcyan;
  display: flex;
}

header .title {
  display: flex;
  flex: 1 1;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.logo {
  display: inline-block;
}

.footer-content {
  width: 100%;
  display: flex !important;
}
.footer-content p{

  width: 100%;
  text-align: center;
  line-height: 2em;

}
.footer-content p .footer-terms-btn{
  color: #0043de;

  border: 0;
  background: rgba(0,0,0,0);
  cursor: pointer;
  font-size: 12px;
  letter-spacing: -0.12px;
}
.nav-links {
  display: flex;
}

.nav-item a {
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
}

.nav-item {
  margin-top: 0px;
}

.col-1 {
  background: #1b283b;
  flex: 1 1;
}

.btn-next,
.btn-prev {
  padding: 6px 12px;
  margin: 0px 10px;
  font-size: 12px;
}

.footer-buttons {
  margin: 14px 0px;
}

.node rect {
  stroke: #333;
  fill: #fff;
}

.edgePath path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

ol {
  display: flex;
  justify-content: space-between;
}

ol li {
  display: block;
  width: 100%;
  flex: auto;
  list-style-type: none;
}
ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #15d9c1;
  border-radius: 1.5em;
  width: 1.5em;
  line-height: 1.5em;
}

.pipeline-ul {
  padding-left: 0px;
}


.table-container table {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22)
}

.table-container table tbody , .table-container table thead {
   border:1px solid #ddd;
  }

.table-container {
 overflow: auto;
 width: 100%;
 padding: 0;
 background-color: transparent;
}
.table-container table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-container table thead tr th.heading {
  padding: 0px;
  color: #eeeeef;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0,0,0,0.05);
  -webkit-transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
  transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.table-container table thead tr th .filter {
  padding:2px;
  width:100%;
  }
  .table-container table thead tr th .filter input{
    height: 22px;
    width:100%;
    font-size: 11px;
    }
.table-container table thead tr th .header-label {
  margin-bottom: 5px;
  padding: 8px;
  background:#15d9c1;
}
  
.table-container table thead tr th.filter input {
    width: 90%;
    height: 25px;
    font-size:11px;
    margin: 0px 2px;
}
.table-container table tbody tr {
  background-color: #ffffff;
}
.table-container table tbody tr td {
  padding: 10px;
  font-size: 12px;
}
.table-container table tbody tr td .view , 
.table-container table tbody tr td .delete ,
.table-container table tbody tr td .display_name {
    cursor: pointer;
}
.pagination {
    padding: 0.5rem;
}
.pagination > select{
    width: 15%;
    border: 1px solid #ccc;
    background: #fff;
}


  .data-source-select{
      width:100%;
  }
  .menu-outer-top .Select-menu-outer {
    bottom: 35px!important;
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-top-right-radius: 4px!important;
    border-top-left-radius: 4px!important;
}
.custom-loading {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ds-info-details {
    display:block;
    min-height:200px;
    max-height:0px;
    padding:10px;
}
.ds-info-details .ds-info{
    display:flex;
    padding:4px;
}
.ds-info-details .ds-info .ds-label{
    width:20%;
    font-weight: bold;
}
.ds-info-details .ds-info .ds-colon{
    width:2%;
    font-weight: bold;
}
.ds-info-details .ds-info .ds-value{
    width:78%;
    max-height: 60px;
    min-height: 0px;
    overflow: auto;
}

.anomaly_flex_container {
  display: flex;
  margin-bottom: 30px;
  flex: 0 1;
}

.anomaly_flex_container > .hierarchy_file_selection {
  width: 40%;
}
.anomaly_flex_container > .hierarchy_file_selection > select {
  height: 30px;
  width: 100%;
}
.anomaly_flex_container > .hierarchy_file_values {
  width: 25%;
  margin-left: 15px;
}
.anomaly_flex_container > .hierarchy_filter_values {
  width: 25%;
  margin-left: 15px;
}

.anomaly_flex_container > .hierarchy_file_values > select {
  height: 30px;
  width: 100%;
}

/* tbody {
    border:1px solid #ddd;
} */

.anomaly-run-btn {
  text-align: right;
}
input,
textarea {
  border: 1px solid #ccc;
}
.hide {
  display: none;
}
.anomaly_radio_btns {
  display: flex;
}
.anomaly_result_table_heading{
  display: flex;
  justify-content: space-between;
  margin-top:15px;
}
.anomaly_result_table_heading>h4{
 margin-top:0px !important;
}
.anomaly_result_table_heading>h4>a:hover{
 cursor: pointer;
 }
 .causal_exp_heading{
  padding: 5px 0px;
  font-size: 14px;
  font-weight: 400;
 }
.hierarchy-value-container{
  display:flex;
  flex-direction: row;
  margin:20px;
}
.hierarchy-value-container > .hierarchy_list {
  flex: 1 1;
  margin-right:20px;
}
.hierarchy-value-container > .hierarchy_values {
  flex:1 1;
  margin-left:20px;
}
.hierarchy-value-container > .hierarchy_values > .picky[aria-expanded="true"]{
  z-index:1
}
.hierarchies_list > label,
.hierarchy_value_list > label {
  width: 15%;
}
.picky__dropdown {
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  /* z-index:0; */
}
input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  /* margin-top: 4px;
  margin-bottom: 8px; */
}
select,
textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 8px;
}
select {
  height: 35px;
}

.picky__input  {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.anomaly_hier_kpi_fex{
  width: 100%;
  margin: 10px auto;
  padding:5px 0px;	
}

.kpi-select{
  margin:20px;
  width:45%;
}
.anomaly-kpi-val-container > fieldset > .kpi-select > div > div > #picky[aria-expanded="true"]{
  z-index:1
}
.anomaly_hier_kpi_fex>.kpi_val{
  padding: 10px;
  flex: 1 1; 
}
.anomaly_hier_kpi_fex>.kpi_val{
  display: flex
}
.anomaly_hier_kpi_fex>.kpi_val>label{
  width: 10%;
  padding-top: 10px;
}
.anomaly_hier_kpi_fex>.kpi_val>.kpi-select{
  width: 100%;
}


.anomaly_algo_flex_container{
  width: 100%;
  margin-bottom:30px;
  padding:5px 0px;	
  display: flex; 
  flex-direction: row;
  margin:20px 5px;
}
.anomaly_algo_flex_container>.algo_values {
  padding: 10px 0px;
  flex: 1 1;
}
.anomaly_algo_flex_container>.algo {
  padding: 10px;
  flex: 1 1;

}

.anomaly_algo_flex_container>.anomaly_algo_param{
  padding: 10px;
  flex:1 1;
  min-height:0px;
  max-height:150px;
  overflow:auto;
}

.anomaly_algo_flex_container>.anomaly_algo_param > form > .field-object > fieldset{
  padding:10px;
}

.anomaly_btns_run_flex{
  display: flex;
  flex-wrap: nowrap;
  width:100%;
 flex-direction: row;
 justify-content: space-between;
 margin-bottom: 20px;
}

.anomaly_ds_btns_run_flex{
  display: flex;
  flex-wrap: nowrap;
  width:100%;
 flex-direction: row-reverse;
 justify-content: space-between;
 margin-bottom: 20px;
}


.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 12px;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-group {
  margin-bottom: 0.5rem;
  font-size: 12px;
}
.form-text {
  display: block;
  margin-top: .25rem;
}
.hidden{
  display: none;
}
.anomaly-run{
  display:flex;
  flex-direction: row-reverse;
}



.hierarchy_file_selection, .hierarchy_file_values, .hierarchy_filter_values {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}
.labelArea 
{
    /* display:table-row; */
    padding:20px;
}
.labelArea>label.heading
{
    text-align:right;
    display:table-cell;
}

.labelArea>label.text
{
    text-align:left;
    display:table-cell;
}
/* .modal-container{

}
.modal-container>.modal-header{
display: flex;
flex-wrap: initial;
background: #337ab7;
padding: 8px;
}
.modal-container>.modal-header>.heading{
  width:95%;
  text-align: center;
  color:#fff;
}
.modal-container>.modal-header>.close:hover{
 cursor: pointer;
}
.modal-container>.modal-header>.close{
  width:5%;
  text-align: center;
  color:#fff;
} */
.pipeline-heading{
  margin-bottom:20px;
}

.algo-params{
  max-width:260px;
  overflow-x: auto;
}
.param_del{
  text-align: center;
}
.ReactTable .rt-resizer{
  z-index: 0 !important;
}

.params_column , .kpi_section{
  min-width: 150px;
  max-width: 0px;
  overflow: auto;
}
.ds_filters{
  white-space: nowrap;
}
.anomaly_result_table_heading > .selection-table-heading{
  padding-top:10px;
}
.anomaly_result_table_heading > div.export {
display: flex;
}
.anomaly_result_table_heading > div.export > .clear-all{
  margin: 0px 20px 2px 2px;
  padding:2px;
}
.anomaly_result_table_heading > div.export > a{
  margin: 4px;
}
.anomaly_result_table_heading > div.export > .csv_download{
 padding-top:4px
}
.auto_generate_modal{
  margin-top:15px;
  min-height: 100px;
  max-height: 0px;
  overflow: auto;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auto_generate_modal > .text {
  text-align: center;
}

.auto_generate_modal_options{
  margin-top:15px;
  display:block;
}

.auto_generate_modal_options > dl > dt.selected_values{
  margin-bottom:3px;
}
.auto_generate_modal_options > dl > dt{
  float: left; clear: left; width: 9em; font-weight: bold;
}
.auto_generate_modal_options > dl > dd{
  float: left; 
}
.upload-anomaly-file {
	display: flex;
  flex-wrap: wrap;
  padding:15px;
}
.upload-anomaly-file .upload-field{
	flex-grow: 1;
	width: 30%;
}
.upload-anomaly-file .upload-or-container{
  width:5%;
  padding:8px;
}
.upload-anomaly-file .upload-path{
  width:55%;
}
.upload-anomaly-file .upload-path .upload-input-field{
  width: 95%;
  padding-left:30px;
  display:flex;
}
.upload-anomaly-file .upload-path .upload-input-field input{
  width: 85%;
  display:flex;
}
.project_ds_container{
  display: flex;
  width: 100%;
  margin-top:8px;
}
.project_ds_container .project_heading{
  width:90%;
  padding-top:8px;
}
.project_ds_container .ds_heading{
  width:8%;
  padding: 6px 5px 6px 7px;

  word-wrap: break-word;
}
.project_ds_container .ds_heading .alert_count{
  background-color: #fff;
  box-shadow:0 2px 5px rgba(0,0,0,0.25), 0 5px 2px rgba(0,0,0,0.22);
  text-align: center;
}
.upload-anomaly-file .upload-path .upload-btn {
  width: 15%;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left:1px;
}
.feedback_flex_container>.feedback_algo_param{
  padding: 10px;
  flex:1 1;
  min-height:0px;
  max-height:450px;
  overflow:auto;
}

.feedback_flex_container>.feedback_algo_param fieldset{
  padding:10px;
}
.ds-result-table{
  padding:8px;
}
.ds-result-heading{
  width: 40%;
  float: left;
  display: block;
  padding: 14px 12px;
}
.line {
  fill: none;
  stroke: #ffab00;
  stroke-width: 2;
}

.dot {
  fill: #ffab00;
  stroke: #fff;
}

.primary {
  fill: none;
  stroke: navy;
}

.upper {
  fill: none;
  stroke: grey;
}

.lower {
  fill: none;
  stroke: grey;
}

div.tooltip-anomaly {
  position: absolute;
  background-color: white;
  max-width: 200px;
  height: auto;
  padding: 1px;
  border-style: solid;
  border: 1px solid #846c6c;
  border-radius: 6px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 1001;
}

.a-chart-wrapper {
  width: 100%;
  height: 400px !important;
}

.csv_download > button{
    border: none;
    outline: none;
    background: #fff;
    color:#0000EE;
}
.csv_download > button:hover{
    cursor:pointer;
    text-decoration: underline;
}
.csv_download > i.fa-download{
    color:#0000EE;
}
.causal-run {
  text-align: right;
}
.causal_strength > select {
  height: 35px;
  width: 30%;
}
.causal_strength {
  width: 30%;
  margin-left: 35px;
}

.btn-back {
  font-size: 10px !important;
}

.link { stroke: #c1c1c1; stroke-opacity: .6; stroke-width: 1px; }
.a-chart-wrapper{
    /* display:flex; */
    
}


.node circle {
    pointer-events: all;
    stroke: #777;
    stroke-width: 1px;
  }


  div.tooltip-causal {
    position: absolute;
    background-color: white;
    max-width: 200px;
    height: auto;
    padding: 1px;
    border-style: solid;
    border: 1px solid #846c6c;
    border-radius: 6px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
    pointer-events: none;
    z-index: 1001;
} 
.svg-graph-container {
  -webkit-transform:translate(10px,0px);
          transform:translate(10px,0px);
  min-height:510px;
  border:1px solid #ccc;
  margin:10px;
} 
.causal_algo_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 20px 0px 20px 20px;
}

.causal_algo_container > .causal_estimation {
  width: 46%;
  margin-right: 20px;
}
.causal_algo_container > .causal_algo_param {
  min-height: 0px;
  max-height: 200px;
  overflow: auto;
  width: 50%;
}
.causal_algo_container > .causal_algo_param > label {
  flex-basis: 24%;
  padding-top: 8px;
}
.causal_algo_container > .causal_algo_param > form {
  width: 100%;
}
.causal_algo_container > .causal_algo_param > form > .field-object {
  width: 100%;
}
.causal_algo_container > .causal_algo_param > form > .field-object > fieldset {
  padding: 8px;
}
.causal_algo_container > .causal_estimation > label {
  flex-basis: 30%;
  padding-top: 8px;
}
.causal_estimate_main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.causal_estimate_main > .causal_estimate_hier > .hierarchy_list > label,
.causal_estimate_main > .causal_estimate_hier > .hierarchy_values > label {
  flex-basis: 30%;
  padding-top: 8px;
}

.causal_estimate_hier > .hierarchy_list > .hier_select {
  width: 100%;
}
.estimate_result_content {
  min-height: 340px;
  overflow: auto;
  max-height: 0px;
}
.estimate_result_content,
.estimate_replicate_contet {
  padding: 10px;
}
.estimate-heading {
  margin-top: 10px;
}
.causal_estimation_replicate {
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
}
.estimate_params_td {
  min-width: 250px;
  max-width: 0px;
  overflow: auto;
}
.btn-add-selection {
  margin-right: 0px;
}
.replicate_add_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


  
.datamap-container {
    max-height: 250px;
    overflow: auto;
}

.over_all_causal {
    display: flex;
    flex-direction: column;
    padding:20px 0px;
}

.causal_upload {
    /* display: flex; */
}

.causal_flex_container {
    display: flex;
    padding:20px 0px;
}

.hierarchy_file_selection, .causal_file, .hierarchy_filter_values {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
}
.causal_file{
    padding: 0px 5px;
}
.causal_relation_heading{
    padding-top:20px;
}
.causal_upload_hier{
    width:100%;
    margin-top:20px;
}
.causal_upload_hier>.hierarchy_list>label,.causal_upload_hier>.hierarchy_values>label{
    width:25%;
    padding-top:10px;
}
.causal_upload_hier > .hierarchy_list > select , .causal_upload_hier>.hierarchy_values > .picky {
    width:60%;
}
.causal_upload{
    width:60%;
    margin:20px;

}
.causal_upload>label{
    width:25%;
    padding-top:3px;
}
.causal_upload>input{
    width:60%;
}
.add_causal_btn{
    width:60%;
    text-align: right;
}
.causal_upload_hier > .hierarchy_list > .hier_select {
    width:60%;
}
.causal_save_add_btns{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 15px;
}
.upload_causal{
    display:flex;
    justify-content: flex-end;
}
.resizer{
    display:inline-block;
    background:#00f;
    width:5px;
    height:100%;
    position:absolute;
    right:0;
    top:0;
    -webkit-transform:translateX(50%);
            transform:translateX(50%);z-index:1
}
.ReactTable .rt-thead.-header{
    background-color:#15d9c1;
    padding: 10px 10px 10px 5px;
    color: #eeeeef;
    font-weight: bold;
    font-size: 14px;
  }

.causal_hier_kpi_fex {
  width: 100%;
  margin: 0 auto;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
}
.causal_hier_kpi_fex > .hier_val {
  padding: 10px 0px;
  flex: 1 1;
}

.kpi_lag_auto {
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
  margin: 20px;
  width: 60%;
}
.kpi_lag_auto > table {
  width: 80%;
}
.kpi_lag_auto > table > tbody > tr > .kpi {
  width: 35%;
}
.kpi_lag_auto > table > tbody > tr > .lag-val {
  width: 35%;
}
.kpi_lag_auto > table > tbody > tr > .checkbox {
  width: 30%;
}
.kpi_lag_auto > table > tbody {
  height:100px;
}
.causal_hier_kpi_fex > .kpi_lag_auto > table {
  border-collapse: separate;
}
.causal_algo_flex_container {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 5px;
  display: flex;
  flex-direction: row;
}
.causal_algo_flex_container > .algo_values {
  padding: 10px 0px;
  display: flex;
  width: 48%;
}
.causal_algo_flex_container > .algo_values > label {
  width: 20%;
  line-height: 40px;
}
.causal_algo_flex_container > .algo_values > .algo-select {
  width: 80%;
}
.causal_algo_flex_container > .causal_algo_param {
  padding: 10px;
  flex: 1 1;
  min-height: 150px;
  max-height: 0px;
  overflow: auto;
  width: 47%;
  margin-right: 20px;
}
.causal_algo_flex_container > .causal_algo_param {
  width: 47%;
  min-height: 0px;
  max-height: 150px;
  overflow: auto;
  margin-left: 20px;
}
.discard-checkboxes {
  display: flex;
}
.discard-checkboxes > a {
  margin: 0px 10px;
}
.discard_fixed_container {
  display: flex;
  flex-direction: row;
  width: 80%;
}
fieldset {
  border: 1px solid #ccc !important;
  margin-bottom: 20px !important;
}
.discard_fixed_container > .discard-edges,
.discard_fixed_container > .fixed-edges {
  flex: 1 1;
}
.discard-Edges-selection {
  border: 1px solid #ddd;
}
.causal_edge_container {
  margin: 10px;
  width: 80%;
}
.causal_edges_table {
  padding: 10px 20px;
}
.selected-discard-edges,
.selected-fixed-edges,
.selected-user-edges {
  width: 80%;
  border: 1px solid #ccc;
  min-height: 70px;
  max-height: 0px;
  overflow: auto;
  margin: 5px 10px;
}

.causal_estimate_upload_links {
  display: flex;
}
.causal_estimate_upload_links > a {
  padding: 10px 0px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  margin: 0px 15px 0px 0px;
}
.causal_estimate_upload_links > a:hover {
  cursor: pointer;
}
.causal_result_replicate_container {
  display: flex;
  flex-direction: column;
}
.causal_result_replicate_hier {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.causal_result_replicate_hier > .hierarchy_list {
  display: flex;
  width: 80%;
}
.causal_result_replicate_hier > .hierarchy_list > .hier_select {
  width: 100%;
}
.causal_result_replicate_hier > .hierarchy_list > label,
.causal_result_replicate_hier > .hierarchy_values > label {
  padding-top: 8px;
  flex-basis: 35%;
}

.causal_result_replicate_hier > .hierarchy_values {
  display: flex;
  width: 80%;
}
.add_replicate_btn {
  width: 100%;
  text-align: right;
}
.edges_table > tbody {
  border: none;
}
.edges_table > tbody > tr > th {
  text-align: left ;
}
.result_kpi_edges_conent{
  min-height: 200px;
  max-height: 0px;
  padding: 10px;
}
.result_kpi_edges_conent > table > tbody > tr > td {
  width:33%;
}
.result_kpi_edges_conent > table > tbody > tr > td > input {
  height:35px;
}
.causal_result_content {
  min-height: 420px;
  max-height: 0px;
  overflow: auto;
}
.causal_result_content,
.causal_result_replicate_content {
  padding: 10px;
}
.causal_replicate_run_selection_list {
  min-height: 100px;
  max-height: 0px;
  overflow: auto;
}
.causal_run_heading {
  margin: 10px 0px;
}
.causal_result_table_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.causal_result_table_heading > .heading_label{
  padding-top:10px;
}
.causal_result_table_heading > .heading_label > span{
  font-size: 14px;
}
.causal_result_table_heading > .delete_all > button{
  margin: 2px;
  min-width: 50px;
}

.add_new_causal_result > a:hover {
  cursor: pointer;
}
.graph-btns-container {
  margin: 20px 0px;
}
.csv-add-new-options {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 5px 0px;
}
.causal_algo_param > form > .form-group > fieldset {
  padding: 10px;
}

.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header thead {
  background: #eeeeef;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
}
.qtip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #3bb4e5;
  border-bottom: 0.05em dotted #3bb4e5;
  box-sizing: border-box;
  font-style: normal;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.qtip:hover {
  color: #069;
  border-bottom: 0.05em dotted #069;
}
/*the tip*/
.qtip:before {
  content: attr(data-tip);
  font-size: 11px;
  position: absolute;
  background: #15d9c1;
  color: #fff;
  line-height: 1.3em;
  padding: 0.5em;
  font-style: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  min-width: 200px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
  font-family: sans-serif;
  letter-spacing: 0;
  font-weight: 600;
}
.qtip:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.qtip:hover:before,
.qtip:hover:after {
  visibility: visible;
  opacity: 1;
}
/*right*/
.qtip.tip-right:before {
  right: 0;
  top: 90%;
  -webkit-transform: translate(calc(100% + 8px), -50%);
          transform: translate(calc(100% + 8px), -50%);
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-right:after {
  border-width: 8px 8px 8px 0;
  border-color: transparent #15d9c1 transparent transparent;
  right: -8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.kpi-auto-container{

  display:flex;
  flex-direction: row;
  justify-content: space-around;
}
.kpi-auto-container > .kpi-list{
    width:50%;
    margin:20px;
}
.kpi-auto-container > .kpi-list > div > #picky[aria-expanded="true"]{
  z-index:1
}
.select-edges-tr > td{
  width:25%;
}
.select-edges-tr > td >  input {
  height:35px;
}
.causal_radio_btns > input {
  width:1.5em;
  height:1.5em
}
.causal_radio_btns > span,label {
 padding-top:5px;
}
.add-new-kpi-link:hover{
  cursor: pointer;
}
.causal_flex_container {
  display: flex;
  margin-bottom:30px;
  flex: 0 1;
}

.causal_flex_container > .hierarchy_file_selection {
  width:40%;
}
.causal_flex_container > .hierarchy_file_selection> select {
  height:30px;
  width:100%;
}
.causal_flex_container > .hierarchy_file_values {
  width:25%;
  margin-left:15px;
}
.causal_flex_container > .hierarchy_filter_values {
  width:25%;
  margin-left:15px;
}

.causal_flex_container > .hierarchy_file_values>select {
 height:30px;
 width:100%;
}

/* tbody {
    border:1px solid #ddd;
} */

.causal-run-btn{
  text-align:right;
  display: flex;
  justify-content: space-between;
  margin-top:20px;
}
.causal-run-btn > .add{
  display: flex;
}
input,textarea {
  border:1px solid #ccc
}
.hide{
  display: none;
}
.causal_radio_btns{
  display: flex;
}
.causal-project-heading_container{
  display: flex;
    justify-content: space-between;
    margin-top: 0px;
  
}
.causal_exp_heading {
  margin-top:10px;
}
.causal-project-heading{

    padding: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 50px;
}
.causal_runs_container > #workflow{
  margin-top:0px ;
}
.heading{
    font-weight: 500;
    font-size:12pt;
}
.text-btn-align{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.accordion{
    margin-top:10px !important;
}
.accordion__heading{
    margin-bottom: 5px !important;
}
.accordion-item{
    margin-bottom:10px !important;
}
.faq-exceldata-img{
    width:100%;
}
* {
  box-sizing: border-box;
}
body {
  /* display: flex; */
  min-height: 100vh;
  flex-direction: row;
  margin: 0;
  font-size: 12px !important;
}

.col-2 {
  display: flex;
  flex-direction: column;
  flex: 5 1;
}
.content {
  display: flex;
  flex-direction: row;
}
.content > article {
  flex: 4 1;
  min-height: 80vh;
}


footer {
  border-top: 1px solid #a2a2a2;
  background-color: #f4f4f4;
}
.body a:hover {
  text-decoration: none;
}

.body a:focus {
  outline: transparent;
}
.btn {
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 10px;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 2;
  border-radius: 3px;
  min-width: 80px;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary {
  background-color: #008cba;
  /* Green */
}

.btn-success {
  background-color: #4caf50;
  /* Green */
}

.btn-alert {
  background-color: #f44336;
  /* Green */
}

.logo_nav {
  display: inline-block;
  width: 100%;
  padding: 20px 0px 0px 15px;
}
.logo_nav img{
   width:120px;
}
.logo_nav a {
  display: block;
  color: white;
  padding: 2px 0px 0px 2px;
}
header .title {
  display: flex;
  flex: 1 1;
  justify-content: center;
  flex-direction: column;
}

.fa .fa-remove {
  color: red;
}

#workflow {
  border-collapse: collapse;
  width: 100%;
  margin-top:2px;
}

#workflow td,
#workflow th {
  border: 1px solid #ddd;
  padding: 8px;
}

#workflow tr:nth-child(even) {
  background-color: #f2f2f2;
}

#workflow tr:hover {
  background-color: #ddd;
}

#workflow th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #15d9c1; /* #1B1B32; */
  color: white;
}

.delete-wf {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.add-btn {
  background-color: #008cba;
  float: right;
}
.button {
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.pipeline-form {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 0px;
  justify-content: center;
  padding: 100px;
}
.form-row {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}
.form-row > label {
  padding: 0.5em 0.1em 0.1em 0;
  width: 45%;
}
.form-row > input {
  border: 1px solid #ddd;
}
.form-row > input,
.form-row > button {
  padding: 0.7em;
}
.form-column > .text {
  text-align: center;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.4rem;
  outline: 0;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.7rem;
  background-color: #008cba;
  color: #fff;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-header .close {
  padding: 0.7rem;
  margin: -0.7rem -0.7rem -0.7rem auto;
  background-color: #008cba;
  outline: none;
  opacity: 0.5;
  outline-color: #008cba;
  border: none;
}
.modal-header .close:hover {
  cursor: pointer;
}
.modal-header .close .fa-close {
  font-size: 14px;
  color: #fff;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  border-top: 1px solid #d8d4d4;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.ReactModal__Overlay {
  background: transparent !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: none !important;
}

.breadcrumb_heading{
    display: flex;
    justify-content: space-between;
}

.breadcrumb_heading > label{
  padding: 20px 0px;
  font-size: 16px;
  font-weight: 600;
}
.navbar > .header-options > li.nav-item > a:hover{
  color:#008CBA;
  background-color:#eeeeee;
}
.navbar > .header-options > li.nav-item:hover > a{
  border: none !important;
  outline: none !important;
}
.m-10{
  margin:10px;
}
.pt-10{
  padding-top:10px !important;
}
.pt-15{
  padding-top:15px !important;
}
#data_frequency_value {
  width:90%;
}
.text-danger{
  color:red;
}
.ds-dropdown {
  margin-bottom:10px;
  width:30%;
}

/*captcha css */
.rnc {
  display: flex;
  flex-direction: row;
  background-color: #008cba26;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 0px 22px 0px 0px;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: 0 0 0px 0;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  width:146px;
}
 
.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.rnc-input {
  border: none;
  padding: 10px 6px;
  font-size: inherit;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #f2f4f5;
} 

button:disabled {
  background-color: #ddd !important;
}
button:disabled :hover {
  cursor:pointer;
}
.table-container table tbody tr td .delete .ds-tooltip {
 z-index:0 !important;
}
.table-container table tbody tr td .delete .ds-tooltip a.disabled {
  cursor: not-allowed;
  text-decoration: none;
  opacity:0.4;
  pointer-events: none;
}
.ReactTable .rt-tbody .rt-td .delete .ds-tooltip a.disabled{
  cursor: not-allowed;
  text-decoration: none;
  opacity:0.4;
  pointer-events: none;
}
.ReactTable .rt-tbody , .ReactTable .rt-tbody .rt-td:last-child {
  overflow:visible;
}
.ReactTable .rt-tbody .rt-td .causal-selection-delete-icon {
  white-space: normal;
}

.ReactTable .rt-tbody .rt-td .causal-selection-delete-icon .ds-tooltip .ds-tooltiptext {
  width:110px !important;
  margin-left: -56px !important;
}


.ds-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: none;
  z-index:999;
}
.ds-tooltip .ds-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #15d9c1;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.ds-tooltip .ds-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 25px;
  border-width: 5px;
  border-style: solid;
  border-color: #15d9c1 transparent transparent transparent;
}

.ds-tooltip:hover .ds-tooltiptext {
  visibility: visible;
  opacity: 1;
  cursor: default;
}
.ds-linked-projects {
  display:flex;
  margin-top:10px;
}
.ds-linked-projects .anomaly-projects {
  width:50%;
  overflow: auto;
  min-height: 100px;
  max-height: 0px;
  margin-right:10px;
}
.ds-linked-projects .causal-projects {
  width:50%;
  overflow: auto;
  min-height: 100px;
  max-height: 0px;
}
.margin-auto {
  margin:auto !important;
}
.text-left{
  text-align: left !important;
}
.error-row{
  display: flex;
  justify-content: flex-start;
}
.error-row label{
  width:32%;
}
.error-row .error-msg{
  color:red;
  width:68%;
}
.text-center{
  text-align: center !important;
}
.change-report{
  width: 20%;
  float: right;
}
.radio_buttons{
  display: inline-flex;
  align-items: center;
}
.whatIfRadio{
  width: 1.2em;
  height: 1.2em;
}
.exp_run_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}
.exp_run_container > .exp_select,
.exp_run_container > .run_select {
  display: flex;
  flex: 1 1;
  margin: 5px;
}
.exp_run_container > .run_select > label {
 text-align: right;
 padding-right:10px;
}
.exp_run_container > .exp_select > label,
.exp_run_container > .run_select > label {
  width: 35%;
  padding-top: 10px;
}
.exp_run_container > .exp_select > .experiment-select,
.exp_run_container > .run_select > .experiment-select{
  width:100%;
}
.pipeline_rca {
  width: 40%;
  display: flex;
  margin: 10px 0px;
}
.pipeline_rca > label {
  width: 20%;
  padding-top: 10px;
  padding-right: 5px;
}
.rca_heading {
  font-size:14px;
  font-weight: 800;
  margin:20px 0px;
}
.run_rca_btn {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}
.rca_form {
  padding: 10px;
}

.rca_form > form > .form-group > #root {
  position: relative;
  flex-basis: inherit;
  border: none !important;
  width: 100%;
  margin-bottom: 0px !important;
}
.rca_form > form > .form-group > #root > .field-string {
  width: 45%;
  display: inline-flex;
  margin: 10px 5px;
}

.rca_form > form > .form-group > #root > .field-string > label {
  width: 30%;
  padding-top: 8px;
}
.rca_kpis{
  margin-bottom:20px;
}
.DS-dropdown{
  width:50%;
}
.predictive-params{
      flex: 1 1;
      min-height: 0px;
      max-height: 150px;
      overflow: auto;
}
.predictive-params > form > .form-group > fieldset{
  padding: 5px !important;
}
.hide-rca-options {
    display: none;
}
.show-rca-options{
    display: block;
}
.predictive_kpi_section_main{
  margin:10px;
}
#kpi_predictive[aria-expanded="true"]{
  min-height:330px;
  max-height: 0px;
  z-index:0;
}
.rca_impact_count{
  display: flex;
  width:35%;
  border-radius: 2px;
}
.rca_impact_count .rca_count{
  width:50%;
  background-color: #fff;
  margin: 5px 4px 5px 6px;
  border-radius: 2px;
  padding:5px;
  box-shadow:0 2px 5px rgba(0,0,0,0.25), 0 5px 2px rgba(0,0,0,0.22);
  text-align: center;
}
.rca_impact_count .impact_count{
  width: 50%;
    background-color: #fff;
    margin: 5px 5px 5px 2px;
    padding: 5px;
    border-radius: 2px;
    box-shadow:0 2px 5px rgba(0,0,0,0.25), 0 5px 2px rgba(0,0,0,0.22);
    text-align: center;
}
.flex_end{
  justify-content: flex-end;
}
.m-0{
  margin:0px !important;
}
.font_20{
  font-size: 20px !important;
}
ol.progtrckr {
    list-style-type: none;
    padding: 0;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
  }
  
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 2px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 2px solid #15D9C1;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 2px solid #15D9C1;
  }
  
  ol.progtrckr li:after {
    content: "\A0\A0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.9rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\39F";
    color: silver;
    background-color: white;
    width: 1.5em;
    line-height: 1.5em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #15D9C1;
    border-radius: 1.5em;
    width: 1.5em;
    line-height: 1.5em;
  }
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #15D9C1;
    width: 1.5em;
    line-height: 1.5em;
    border-radius: 1.5em;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }
  
  .progtrckr {
    width: 100%;
    margin: 30px 0px;
  }
  
  ol.progtrckr li {
    line-height: 2.4rem;
  }
  ol.progtrckr li:before{
    bottom: -1.9rem;
  }
  ol.progtrckr li.progtrckr-todo:before{
    background: #EEEEEF;
  }
.notifications{
  position: relative;
}
.button__badge {
  background-color: #fa3e3e;
  border-radius: 25px;
  color: white;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
  top: 0;
  right: 0;
}
.alert-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
}

.upload_btns{
    display: flex;
    justify-content: center;
    margin:20px;
    min-height:200px;
    max-height:0px;
}
.upload-main {
  display: flex;
}

.upload-main > .upload-ip {
  flex: 1 1;
  order: 2;
}

.upload-main > .nav {
  flex: 0 0 20vw;
}

.upload-main > .nav {
  order: 1;
}

.data-selection {
  display: flex;
  flex-direction: column;
}

.button {
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.upload-btn {
  background-color: #008cba;
}

.flex-container {
  display: flex;
}

.flex-container > .filename {
  padding: 10px 15px 10px 0px;
  font-weight: 600;
  font-size: 14px;
}

.dropzone {
  border: 1px dotted dodgerblue;
  padding: 20px;
}

/* upload section css */

.parent{
  display:flex;
  flex-direction:row;
  width:100%

}
.parent .child1{
  width:23%;
  border:1px solid blue;
  height:30px;
}

.upload_flex_container {
  display: flex;
  background-color: #fff;
  align-items: center;
  margin: 20px 0px;
  justify-content: space-between;
}

.upload_flex_container > .upload_csv, .uploaded_csv {
  width:40%;
  background-color: #fff;
  color: #544b4b;
  margin: 0px;
  text-align: left;
  line-height: 2em;
  padding-top: 20px;
}
.upload_flex_container > .upload_csv > label{
  display: block;
}
.upload_flex_container > .upload_csv > input{
  width:80%;
}

.upload_flex_container > .upload_csv > .react-csv-input {
  padding: 0px !important;
}
.upload_flex_container > .config_upload{
  display:flex;
  width:25%;
}

.upload_flex_container > .frequency {
  width: 25%;
  justify-content: center;
}

.upload_flex_container > .window_main {
  width: 25%;
  justify-content: center;
}
.upload_flex_container > .window_main > .window > input {
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
}

.hierarchy_flex-container {
  display: flex;
  background-color: #fff;
}

.hierarchy_flex-container > .master_column_main,
.time_column_main,
.kpi_column_main,
.hierarchy_column_main {
  background-color: #fff;
  width: 20%;
  margin: 10px;
  font-size: 16px;
}

.hierarchy_flex-container > .master_column_main > .master_column {
  width: 100%;
  border: 1px solid #222222;
  min-height: 300px;
  max-height: 0px;
  overflow: auto;
  margin-top: 5px;
  background: lightcyan;
}

.hierarchy_flex-container > .time_column_main > .time_column,
.hierarchy_flex-container > .kpi_column_main > .kpi_column,
.hierarchy_flex-container > .hierarchy_column_main > .hierarchy_column {
  width: 100%;
  border: 1px solid #222222;
  min-height: 300px;
  max-height: 0px;
  overflow: auto;
  margin-top: 5px;
  background: #f1f1f1;
}

.hierarchy_flex-container > .master_column_main > h4,
.hierarchy_flex-container > .time_column_main > h4,
.hierarchy_flex-container > .kpi_column_main > h4,
.hierarchy_flex-container > .hierarchy_column_main > h4 {
  text-align: left;
  margin: 5px;
}

.hierarchy_flex-container > .two {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.hierarchy_flex-container > .three {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.hierarchy_flex-container > .four {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.hierarchy_flex-container > .five {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.done-kpi {
  background: #fff;
  height: 250px;
  width: 200px;
  margin: 10px;
  border: 1px solid #ddd;
}

.done-hierarchy {
  background: #fff;
  height: 250px;
  width: 200px;
  margin: 10px;
  border: 1px solid #ddd;
}

.kpi_section_main {
  width: 27%;
}
.kpi_section_main > .table_kpi_fixed_header {
  display:flex;
  flex-direction: column;
  width:100%
}
.kpi_section_main > .table_kpi_fixed_header > .header {
  width:100%;
  display: flex;
  font-weight: 500;
  padding: 0px 5px 15px 0px;

}
.kpi_section_main > .table_kpi_fixed_header > .header > .kpi {
  width:60%;
}
.kpi_section_main > .table_kpi_fixed_header > .header > .type {
  width:25%;
}
.kpi_section_main > .table_kpi_fixed_header > .header > .ratio {
  width:10%;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body  {
  max-height:330px;
  overflow: auto;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row {
  width:100%;
  display: flex;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_name {
  width:60%;
  word-break: break-word;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_type{
  width:25%;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_type > select {
  width:-webkit-max-content;
  width:-moz-max-content;
  width:max-content;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_checkbox {
  width:15%;
  display: flex;
  justify-content: center;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_checkbox > input{
  width:25px;
  height:20px;
}
.no-visibility {
  visibility: hidden;
}

.draggable_value {
  padding: 8px 0px;
  background: aqua;
  border: 1px solid #dddd;
  margin: 2px;
  -webkit-columns: #222222;
     -moz-columns: #222222;
          columns: #222222;
}

/* TO-DO */
.hierarchy_flex_container {
  display: flex;
  max-height: 300px;
  background: white;

  max-width: 100%;
  /* overflow-x: scroll; */
}



.hide {
  display: none;
}
.show {
  display: block;
}

.file_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
.uploaded_f {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: row;
}
.h_files {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  overflow: auto;
  max-width: 70%;
}
/* .uploaded_f div:nth-child(even){background-color: #f2f2f2;} */

.uploaded_f div:hover {
  background-color: #ddd;
}

.f_name,
.d_operation {
  padding: 5px;
}

.change-uploadfile:hover {
  cursor: pointer;
}


.file_uploads_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 0px 10px 0px 0px;
}

.kpi_section_main {
  display: flex;
  justify-content: space-evenly;
}
.kpi_section_main > .ratio {
  margin-top: 0px;
}
.multiselect_container {
  display: flex;
  min-height: 400px;
  max-height: 0px;
}
.multiselect_container > .mulitiselect_panels {
  width: 24%;
  margin: 5px;
}

.upload_flex_container > .batch-heading > span {
  font-weight: 500;
  padding-left:8px;
}
.multiselect_container > .mulitiselect_panels > div > .picky > .picky__dropdown {
  z-index:0 !important;
}
.objectie-kpi-threshold , .alert-sum-params-container{
    display: flex;
    width:100%;
    justify-content:space-between
}
.objectie-kpi-threshold > .objectie-kpi,.threshold,.other-columns{
    width:32%;
}
.objectie-kpi , .other-columns{
    display:flex;
}
.objectie-kpi > label , .other-columns > label {
    width:40%;
    padding-top:8px;
}
.objectie-kpi > .objective-select , .other-columns > .objective-select{
    width:100%;
}
.threshold{
    display:flex;
}
.threshold > label{
    width:25%;
    padding-top:8px;
}
.source-kpi-container {
    max-height: 300px;
    margin-top:10px;
    min-height: 0px;
    overflow: auto;
}
.alert-sum-params-container .alert-sum-block{
    width:32%;
    display: flex;
    margin-top:15px;
    margin-right:6px;
}
.alert-sum-params-container .alert-sum-block label{
    width:35%;
}
.alert-sum-params-container .alert-sum-block .alert-sum-selection{
    width:60%;
}
.alert-sum-params-container .alert-sum-block .info-container{
    width:5%;
    margin-top:6px;
}
.alert-sum-params-container .alert-sum-block .sum-tooltip{
    z-index: 0 !important;
}

.alert-sum-fieldset {
    margin-top:20px;
    padding:2px 10px
}
.alert-sum-fieldset legend{
    padding:2px 20px
}
.add-row{
    width: 100%;
    text-align: right;
}
.add-row button{
    color:#0052CC;
    border:none;
    background-color: #fff;
}
.add-row button:disabled{
    color:#ccc !important;
    border:none !important;
    background-color: #fff !important;
}
.delete .disabled{
    cursor: not-allowed;
    text-decoration: none;
    opacity: 0.4;
    pointer-events: none;
}
.text-right{
    text-align:right;
    padding-right:10px;
}

.datamap-container {
  display: flex;
  flex-direction: column;
  background: white;
}
.hierarchy-data {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid grey;
}
.hierarchy-data .del-hybrid:hover {
  cursor: pointer;
  text-decoration: underline;
}

.h-delete {
  flex: 1 0 10%;
}
.h-row {
  flex: 1 0 60%;
  overflow-x: auto;
}
.h-file {
  flex: 1 0 20%;
}
.none {
  display: none;
}
.upload-data-btn {
  text-align: right;
}
.add-new-container {
  display: flex;
  margin: 15px 0px;
}
.add-new-container > button {
  margin-left: 0px;
}
.input-container {
  display: flex;
  margin: 15px 0px;
}
.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}
.input-field {
  outline: none;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #ddd;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  font-weight: 900;
}
.or-container {
  max-width: 100%;
  margin: 20px auto;
}

.hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(#ccc), to(#ccc));
  background: linear-gradient(to right, #ccc, #ccc);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #111111;
  font-weight: bolder;
  background-color: #eeeeef;
}

.batch-upload-container{
    max-height:500px;
    min-height:300px;
    overflow: auto;
}
.batch-heading{
    padding:20px 0px;
}
.batch-upload-btns{
    display: flex;
    justify-content: flex-end;
}
.hierarchy_sets_main{
width:20%;
border:1px solid #ddd;
}


/* upload section css */
.hierarchySet_flex-container{
    display: flex;
    background-color: #fff;
    padding:20px;
  }
  
 
  .hierarchySet_flex-container > .set_input{
    display: flex;
    justify-content: space-between;
    width: 35%;
  }
  
  
  .hierarchySet_flex-container > .set_input > label{
    line-height: 30px;
    width: 15%;
  }
  
  .hierarchySet_flex-container > .set_input > .create-set{
   width:55%;
  }
  .hierarchySet_flex-container > .set_input > .create-set > input{
    line-height:20px;
    margin-top:1px;
   }
 

  .hierarchySet_flex-container >  .set_input > .submit-btn{
    width:35%;
  }
  .hierarchySet_flex-container >  .set_input > .submit-btn > input{
    margin-top:0px;
    margin-left:2px;
  }

  .hierarchySet_Creation_flex-container {
    display: flex;
    background-color: #fff;
    padding:20px;
    min-height: 360px;
    max-height: 0px;
  }
  
  .hierarchySet_Creation_flex-container > .master_column_main   {
    background-color: #fff;
    width: 20%;
    margin: 10px;
  }

  .hierarchySet_Creation_flex-container > .set_creation_main   {
    background-color: #fff;
      max-width: 1000px; 
    display: flex;
     overflow: auto;
  }
  .hierarchySet_Creation_flex-container > .set_creation_main > div > .droppable-column:first-child {
    position: absolute;
    margin-left: -250px;
  }
  .hierarchySet_Creation_flex-container > .set_creation_main > div > .droppable-column {
    width:240px;
  }
  .hierarchySet_Creation_flex-container > .set_creation_main >  div > .droppable-column:first-child > h5 {
    background: #008cba;
    color: #fff;
    padding: 8px;
  }
  .hierarchySet_Creation_flex-container > .master_column_main >.master_column, .hierarchySet_Creation_flex-container > .set_creation_main > .set_creation{
    width: 100%;
    border: 1px solid #222222;
    min-height: 300px;
    max-height: 0px;
    overflow: auto;
    margin-top: 5px;
    background: lightcyan;
  }
  
  
  .hierarchy_flex-container > .master_column_main>h4,.hierarchy_flex-container > .time_column_main>  h4,.hierarchy_flex-container > .kpi_column_main>  h4,.hierarchy_flex-container > .hierarchy_column_main>  h4{
    text-align: center;
      margin: 5px;
  }
.set_col_dynamic{
    width: 100%;
    border: 1px solid #222222;
    min-height: 300px;
    max-height: 0px;
    overflow: auto;
    margin-top: 5px;
    background: lightcyan;
    display: block;
}

.back-btn {
  margin:2px 0px;
}

.h-map-container > .default-h-map , .hybrid-h-map{
  width:60%;
  margin-bottom:20px;
  border-collapse: collapse;
}
.h-map-container > .default-h-map > tbody > tr > td ,
.h-map-container > .hybrid-h-map > tbody > tr > td
{
 border: 2px solid #DFE1E6;
 padding:8px;
}

.hybrid-btns{
  display: flex;
  justify-content: flex-end;
}
.hybrid-btns > .btn{
  margin-right: 0px;
}
.hybrid_set_creation_main{
  display: flex;
  overflow: auto;
  min-width: 641px;
  max-width: 0px;
  margin-right: 10px;
}
.hybrid-hierarchy-container {
  min-height: 200px;
  max-height: 420px;
  background-color:#fff;
  margin-bottom: 10px;
}
.hybrid-hierarchy-container > .no-sets{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
}

.hybrid_set_creation_main > .hybrid-set-column  {
  width: 210px !important;
}

.hybrid_set_creation_main > .hybrid-set-column > .draggable-hybrid-set,
.hybrid_set_creation_main > .cusom-hybrid-droppabe-container > .cusom-hybrid-droppabe-set
{
  width: 203px !important;
}

.hybrid_set_creation_main > .cusom-hybrid-droppabe-container:last-child {
  width: 210px !important;
  position: absolute;
  -webkit-transform: translate(641px, 0px);
          transform: translate(641px, 0px);
}
.selected_hybrid {
    margin-left:220px;
}
.incremental_run_btns{
    text-align:right;
}
.privacy-info {
    display:block;
    min-height:140px;
    max-height:0px;
    padding:10px;
}
.privacy-info p {
    line-height: 1.8;
}
.privacy-modal-footer{
    border:none !important;
    padding:0px;
}
/* @import url('https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css');*/
.layout {
  background: rgba(58, 63, 68, 0.5);
  border-radius: 5px;
  box-shadow: 0 1.5px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

/* 
body {
  display: flex;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  color: #fff;
  background: #222222;
  background: #16222A;
  background: -webkit-linear-gradient(to top, #16222A, #3A6073);
  background: linear-gradient(to top, #16222A, #3A6073);
}
 */

.chart {
  display: flex;
  flex: 0 1 50%;
  justify-content: center;
  align-items: center;
  background: #2BBDCA;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.login-form {
  background: #162845;
  border-radius: 5px;
  box-shadow: 0 1.5px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex: 0 1 50%;
  min-height: 100vh;
  text-align: center;
  color: #FFFFFF;
  min-height: 100vh;
  justify-content: center;
}

.login-form .acc-logo{
    padding:10px;
}
.login-form .acc-logo img{
  width:120px;
}
#title-heading{
  font-size: 1.5rem !important;
}
.login-form .ms-logo{
  margin-top:25px;
}
.login-form .ms-logo img{
  width:150px;
}

.wt-text {
  display: flex;
  width: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  min-height: 60vh;
  justify-content: center;
  /* align-items: center; */
}

.wt-text {
  background: url(/static/media/wt-bg.e0371000.png) 97% 50% no-repeat;
  mix-blend-mode: screen;
}

.logo {
 
  text-align: center;
 
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 1.3em;
  padding: 0px 2px;
  width: 100%;

}

.login-item {
  color: #ffff;
  padding: 25px 25px 0;
  margin: 20px 20px 0;
  border-radius: 3px;
}



.form input[type="password"], .form input[type="text"], .form input[type="submit"] {
  width: 100%;
}

.form-login label, .form-login input[type="text"], .form-login input[type="password"], .form-login input[type="submit"] {
  border-radius: 0.25rem;
  padding: 1rem 0.5rem;
  color: #3A3F44;
}

.form-login input[type="text"], .form-login input[type="password"] {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color:#fff !important;
}

.form-login input[type="text"]:focus, .form-login input[type="text"]:hover, .form-login input[type="password"]:focus, .form-login input[type="password"]:hover {
  background-color: #eeeeee;
  color:#fff !important;
}

.form-login input[type="submit"] {
  background-color: #00B9BC;
  color: #eee;
  font-weight: bold;
  text-transform: uppercase;
}

.form-login input[type="submit"]:focus, .form-login input[type="submit"]:hover {
  background-color: #197071;
  color:#fff !important;
}
.login-form-field{
  margin-bottom: 2rem;
}

.form-field {
  display: flex;
  margin-bottom: 2rem;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text--center {
  text-align: center;
}

.form-input {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #4E5A6C !important;
  color: white;
}
.login-btn{
  border: 3px solid #2BBDCA  !important;
  background: transparent !important;
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.8);
  width: 130px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 32px;
    height: 32px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.has-error .help-block {
  color:red;
  text-align: left;
}
