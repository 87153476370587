.causal_algo_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 20px 0px 20px 20px;
}

.causal_algo_container > .causal_estimation {
  width: 46%;
  margin-right: 20px;
}
.causal_algo_container > .causal_algo_param {
  min-height: 0px;
  max-height: 200px;
  overflow: auto;
  width: 50%;
}
.causal_algo_container > .causal_algo_param > label {
  flex-basis: 24%;
  padding-top: 8px;
}
.causal_algo_container > .causal_algo_param > form {
  width: 100%;
}
.causal_algo_container > .causal_algo_param > form > .field-object {
  width: 100%;
}
.causal_algo_container > .causal_algo_param > form > .field-object > fieldset {
  padding: 8px;
}
.causal_algo_container > .causal_estimation > label {
  flex-basis: 30%;
  padding-top: 8px;
}
.causal_estimate_main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.causal_estimate_main > .causal_estimate_hier > .hierarchy_list > label,
.causal_estimate_main > .causal_estimate_hier > .hierarchy_values > label {
  flex-basis: 30%;
  padding-top: 8px;
}

.causal_estimate_hier > .hierarchy_list > .hier_select {
  width: 100%;
}
.estimate_result_content {
  min-height: 340px;
  overflow: auto;
  max-height: 0px;
}
.estimate_result_content,
.estimate_replicate_contet {
  padding: 10px;
}
.estimate-heading {
  margin-top: 10px;
}
.causal_estimation_replicate {
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
}
.estimate_params_td {
  min-width: 250px;
  max-width: 0px;
  overflow: auto;
}
.btn-add-selection {
  margin-right: 0px;
}
.replicate_add_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
