.accordion{
    margin-top:10px !important;
}
.accordion__heading{
    margin-bottom: 5px !important;
}
.accordion-item{
    margin-bottom:10px !important;
}
.faq-exceldata-img{
    width:100%;
}