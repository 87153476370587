.custom-loading {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}