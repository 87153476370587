.anomaly_hier_kpi_fex{
  width: 100%;
  margin: 10px auto;
  padding:5px 0px;	
}

.kpi-select{
  margin:20px;
  width:45%;
}
.anomaly-kpi-val-container > fieldset > .kpi-select > div > div > #picky[aria-expanded="true"]{
  z-index:1
}
.anomaly_hier_kpi_fex>.kpi_val{
  padding: 10px;
  flex: 1; 
}
.anomaly_hier_kpi_fex>.kpi_val{
  display: flex
}
.anomaly_hier_kpi_fex>.kpi_val>label{
  width: 10%;
  padding-top: 10px;
}
.anomaly_hier_kpi_fex>.kpi_val>.kpi-select{
  width: 100%;
}


.anomaly_algo_flex_container{
  width: 100%;
  margin-bottom:30px;
  padding:5px 0px;	
  display: flex; 
  flex-direction: row;
  margin:20px 5px;
}
.anomaly_algo_flex_container>.algo_values {
  padding: 10px 0px;
  flex: 1;
}
.anomaly_algo_flex_container>.algo {
  padding: 10px;
  flex: 1;

}

.anomaly_algo_flex_container>.anomaly_algo_param{
  padding: 10px;
  flex:1;
  min-height:0px;
  max-height:150px;
  overflow:auto;
}

.anomaly_algo_flex_container>.anomaly_algo_param > form > .field-object > fieldset{
  padding:10px;
}

.anomaly_btns_run_flex{
  display: flex;
  flex-wrap: nowrap;
  width:100%;
 flex-direction: row;
 justify-content: space-between;
 margin-bottom: 20px;
}

.anomaly_ds_btns_run_flex{
  display: flex;
  flex-wrap: nowrap;
  width:100%;
 flex-direction: row-reverse;
 justify-content: space-between;
 margin-bottom: 20px;
}


.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 12px;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-group {
  margin-bottom: 0.5rem;
  font-size: 12px;
}
.form-text {
  display: block;
  margin-top: .25rem;
}
.hidden{
  display: none;
}
.anomaly-run{
  display:flex;
  flex-direction: row-reverse;
}



.hierarchy_file_selection, .hierarchy_file_values, .hierarchy_filter_values {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}
.labelArea 
{
    /* display:table-row; */
    padding:20px;
}
.labelArea>label.heading
{
    text-align:right;
    display:table-cell;
}

.labelArea>label.text
{
    text-align:left;
    display:table-cell;
}
/* .modal-container{

}
.modal-container>.modal-header{
display: flex;
flex-wrap: initial;
background: #337ab7;
padding: 8px;
}
.modal-container>.modal-header>.heading{
  width:95%;
  text-align: center;
  color:#fff;
}
.modal-container>.modal-header>.close:hover{
 cursor: pointer;
}
.modal-container>.modal-header>.close{
  width:5%;
  text-align: center;
  color:#fff;
} */
.pipeline-heading{
  margin-bottom:20px;
}

.algo-params{
  max-width:260px;
  overflow-x: auto;
}
.param_del{
  text-align: center;
}
.ReactTable .rt-resizer{
  z-index: 0 !important;
}

.params_column , .kpi_section{
  min-width: 150px;
  max-width: 0px;
  overflow: auto;
}
.ds_filters{
  white-space: nowrap;
}
.anomaly_result_table_heading > .selection-table-heading{
  padding-top:10px;
}
.anomaly_result_table_heading > div.export {
display: flex;
}
.anomaly_result_table_heading > div.export > .clear-all{
  margin: 0px 20px 2px 2px;
  padding:2px;
}
.anomaly_result_table_heading > div.export > a{
  margin: 4px;
}
.anomaly_result_table_heading > div.export > .csv_download{
 padding-top:4px
}
.auto_generate_modal{
  margin-top:15px;
  min-height: 100px;
  max-height: 0px;
  overflow: auto;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auto_generate_modal > .text {
  text-align: center;
}

.auto_generate_modal_options{
  margin-top:15px;
  display:block;
}

.auto_generate_modal_options > dl > dt.selected_values{
  margin-bottom:3px;
}
.auto_generate_modal_options > dl > dt{
  float: left; clear: left; width: 9em; font-weight: bold;
}
.auto_generate_modal_options > dl > dd{
  float: left; 
}
.upload-anomaly-file {
	display: flex;
  flex-wrap: wrap;
  padding:15px;
}
.upload-anomaly-file .upload-field{
	flex-grow: 1;
	width: 30%;
}
.upload-anomaly-file .upload-or-container{
  width:5%;
  padding:8px;
}
.upload-anomaly-file .upload-path{
  width:55%;
}
.upload-anomaly-file .upload-path .upload-input-field{
  width: 95%;
  padding-left:30px;
  display:flex;
}
.upload-anomaly-file .upload-path .upload-input-field input{
  width: 85%;
  display:flex;
}
.project_ds_container{
  display: flex;
  width: 100%;
  margin-top:8px;
}
.project_ds_container .project_heading{
  width:90%;
  padding-top:8px;
}
.project_ds_container .ds_heading{
  width:8%;
  padding: 6px 5px 6px 7px;

  word-wrap: break-word;
}
.project_ds_container .ds_heading .alert_count{
  background-color: #fff;
  box-shadow:0 2px 5px rgba(0,0,0,0.25), 0 5px 2px rgba(0,0,0,0.22);
  text-align: center;
}
.upload-anomaly-file .upload-path .upload-btn {
  width: 15%;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left:1px;
}
.feedback_flex_container>.feedback_algo_param{
  padding: 10px;
  flex:1;
  min-height:0px;
  max-height:450px;
  overflow:auto;
}

.feedback_flex_container>.feedback_algo_param fieldset{
  padding:10px;
}
.ds-result-table{
  padding:8px;
}
.ds-result-heading{
  width: 40%;
  float: left;
  display: block;
  padding: 14px 12px;
}