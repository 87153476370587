.datamap-container {
  display: flex;
  flex-direction: column;
  background: white;
}
.hierarchy-data {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid grey;
}
.hierarchy-data .del-hybrid:hover {
  cursor: pointer;
  text-decoration: underline;
}

.h-delete {
  flex: 1 0 10%;
}
.h-row {
  flex: 1 0 60%;
  overflow-x: auto;
}
.h-file {
  flex: 1 0 20%;
}
.none {
  display: none;
}
.upload-data-btn {
  text-align: right;
}
.add-new-container {
  display: flex;
  margin: 15px 0px;
}
.add-new-container > button {
  margin-left: 0px;
}
.input-container {
  display: -ms-flexbox;
  display: flex;
  margin: 15px 0px;
}
.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}
.input-field {
  outline: none;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #ddd;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  font-weight: 900;
}
.or-container {
  max-width: 100%;
  margin: 20px auto;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, #ccc, #ccc);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #111111;
  font-weight: bolder;
  background-color: #eeeeef;
}
