.h-map-container > .default-h-map , .hybrid-h-map{
  width:60%;
  margin-bottom:20px;
  border-collapse: collapse;
}
.h-map-container > .default-h-map > tbody > tr > td ,
.h-map-container > .hybrid-h-map > tbody > tr > td
{
 border: 2px solid #DFE1E6;
 padding:8px;
}

.hybrid-btns{
  display: flex;
  justify-content: flex-end;
}
.hybrid-btns > .btn{
  margin-right: 0px;
}
.hybrid_set_creation_main{
  display: flex;
  overflow: auto;
  min-width: 641px;
  max-width: 0px;
  margin-right: 10px;
}
.hybrid-hierarchy-container {
  min-height: 200px;
  max-height: 420px;
  background-color:#fff;
  margin-bottom: 10px;
}
.hybrid-hierarchy-container > .no-sets{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
}

.hybrid_set_creation_main > .hybrid-set-column  {
  width: 210px !important;
}

.hybrid_set_creation_main > .hybrid-set-column > .draggable-hybrid-set,
.hybrid_set_creation_main > .cusom-hybrid-droppabe-container > .cusom-hybrid-droppabe-set
{
  width: 203px !important;
}

.hybrid_set_creation_main > .cusom-hybrid-droppabe-container:last-child {
  width: 210px !important;
  position: absolute;
  transform: translate(641px, 0px);
}
.selected_hybrid {
    margin-left:220px;
}