.privacy-info {
    display:block;
    min-height:140px;
    max-height:0px;
    padding:10px;
}
.privacy-info p {
    line-height: 1.8;
}
.privacy-modal-footer{
    border:none !important;
    padding:0px;
}