.anomaly_flex_container {
  display: flex;
  margin-bottom: 30px;
  flex: 0 1;
}

.anomaly_flex_container > .hierarchy_file_selection {
  width: 40%;
}
.anomaly_flex_container > .hierarchy_file_selection > select {
  height: 30px;
  width: 100%;
}
.anomaly_flex_container > .hierarchy_file_values {
  width: 25%;
  margin-left: 15px;
}
.anomaly_flex_container > .hierarchy_filter_values {
  width: 25%;
  margin-left: 15px;
}

.anomaly_flex_container > .hierarchy_file_values > select {
  height: 30px;
  width: 100%;
}

/* tbody {
    border:1px solid #ddd;
} */

.anomaly-run-btn {
  text-align: right;
}
input,
textarea {
  border: 1px solid #ccc;
}
.hide {
  display: none;
}
.anomaly_radio_btns {
  display: flex;
}
.anomaly_result_table_heading{
  display: flex;
  justify-content: space-between;
  margin-top:15px;
}
.anomaly_result_table_heading>h4{
 margin-top:0px !important;
}
.anomaly_result_table_heading>h4>a:hover{
 cursor: pointer;
 }
 .causal_exp_heading{
  padding: 5px 0px;
  font-size: 14px;
  font-weight: 400;
 }