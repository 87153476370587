.csv_download > button{
    border: none;
    outline: none;
    background: #fff;
    color:#0000EE;
}
.csv_download > button:hover{
    cursor:pointer;
    text-decoration: underline;
}
.csv_download > i.fa-download{
    color:#0000EE;
}