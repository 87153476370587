.causal-run {
  text-align: right;
}
.causal_strength > select {
  height: 35px;
  width: 30%;
}
.causal_strength {
  width: 30%;
  margin-left: 35px;
}

.btn-back {
  font-size: 10px !important;
}
