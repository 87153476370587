.causal_hier_kpi_fex {
  width: 100%;
  margin: 0 auto;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
}
.causal_hier_kpi_fex > .hier_val {
  padding: 10px 0px;
  flex: 1;
}

.kpi_lag_auto {
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
  margin: 20px;
  width: 60%;
}
.kpi_lag_auto > table {
  width: 80%;
}
.kpi_lag_auto > table > tbody > tr > .kpi {
  width: 35%;
}
.kpi_lag_auto > table > tbody > tr > .lag-val {
  width: 35%;
}
.kpi_lag_auto > table > tbody > tr > .checkbox {
  width: 30%;
}
.kpi_lag_auto > table > tbody {
  height:100px;
}
.causal_hier_kpi_fex > .kpi_lag_auto > table {
  border-collapse: separate;
}
.causal_algo_flex_container {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 5px;
  display: flex;
  flex-direction: row;
}
.causal_algo_flex_container > .algo_values {
  padding: 10px 0px;
  display: flex;
  width: 48%;
}
.causal_algo_flex_container > .algo_values > label {
  width: 20%;
  line-height: 40px;
}
.causal_algo_flex_container > .algo_values > .algo-select {
  width: 80%;
}
.causal_algo_flex_container > .causal_algo_param {
  padding: 10px;
  flex: 1;
  min-height: 150px;
  max-height: 0px;
  overflow: auto;
  width: 47%;
  margin-right: 20px;
}
.causal_algo_flex_container > .causal_algo_param {
  width: 47%;
  min-height: 0px;
  max-height: 150px;
  overflow: auto;
  margin-left: 20px;
}
.discard-checkboxes {
  display: flex;
}
.discard-checkboxes > a {
  margin: 0px 10px;
}
.discard_fixed_container {
  display: flex;
  flex-direction: row;
  width: 80%;
}
fieldset {
  border: 1px solid #ccc !important;
  margin-bottom: 20px !important;
}
.discard_fixed_container > .discard-edges,
.discard_fixed_container > .fixed-edges {
  flex: 1;
}
.discard-Edges-selection {
  border: 1px solid #ddd;
}
.causal_edge_container {
  margin: 10px;
  width: 80%;
}
.causal_edges_table {
  padding: 10px 20px;
}
.selected-discard-edges,
.selected-fixed-edges,
.selected-user-edges {
  width: 80%;
  border: 1px solid #ccc;
  min-height: 70px;
  max-height: 0px;
  overflow: auto;
  margin: 5px 10px;
}

.causal_estimate_upload_links {
  display: flex;
}
.causal_estimate_upload_links > a {
  padding: 10px 0px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  margin: 0px 15px 0px 0px;
}
.causal_estimate_upload_links > a:hover {
  cursor: pointer;
}
.causal_result_replicate_container {
  display: flex;
  flex-direction: column;
}
.causal_result_replicate_hier {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.causal_result_replicate_hier > .hierarchy_list {
  display: flex;
  width: 80%;
}
.causal_result_replicate_hier > .hierarchy_list > .hier_select {
  width: 100%;
}
.causal_result_replicate_hier > .hierarchy_list > label,
.causal_result_replicate_hier > .hierarchy_values > label {
  padding-top: 8px;
  flex-basis: 35%;
}

.causal_result_replicate_hier > .hierarchy_values {
  display: flex;
  width: 80%;
}
.add_replicate_btn {
  width: 100%;
  text-align: right;
}
.edges_table > tbody {
  border: none;
}
.edges_table > tbody > tr > th {
  text-align: left ;
}
.result_kpi_edges_conent{
  min-height: 200px;
  max-height: 0px;
  padding: 10px;
}
.result_kpi_edges_conent > table > tbody > tr > td {
  width:33%;
}
.result_kpi_edges_conent > table > tbody > tr > td > input {
  height:35px;
}
.causal_result_content {
  min-height: 420px;
  max-height: 0px;
  overflow: auto;
}
.causal_result_content,
.causal_result_replicate_content {
  padding: 10px;
}
.causal_replicate_run_selection_list {
  min-height: 100px;
  max-height: 0px;
  overflow: auto;
}
.causal_run_heading {
  margin: 10px 0px;
}
.causal_result_table_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.causal_result_table_heading > .heading_label{
  padding-top:10px;
}
.causal_result_table_heading > .heading_label > span{
  font-size: 14px;
}
.causal_result_table_heading > .delete_all > button{
  margin: 2px;
  min-width: 50px;
}

.add_new_causal_result > a:hover {
  cursor: pointer;
}
.graph-btns-container {
  margin: 20px 0px;
}
.csv-add-new-options {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 5px 0px;
}
.causal_algo_param > form > .form-group > fieldset {
  padding: 10px;
}

.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header thead {
  background: #eeeeef;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
}
.qtip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #3bb4e5;
  border-bottom: 0.05em dotted #3bb4e5;
  box-sizing: border-box;
  font-style: normal;
  transition: all 0.25s ease-in-out;
}
.qtip:hover {
  color: #069;
  border-bottom: 0.05em dotted #069;
}
/*the tip*/
.qtip:before {
  content: attr(data-tip);
  font-size: 11px;
  position: absolute;
  background: #15d9c1;
  color: #fff;
  line-height: 1.3em;
  padding: 0.5em;
  font-style: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  min-width: 200px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
  font-family: sans-serif;
  letter-spacing: 0;
  font-weight: 600;
}
.qtip:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.qtip:hover:before,
.qtip:hover:after {
  visibility: visible;
  opacity: 1;
}
/*right*/
.qtip.tip-right:before {
  right: 0;
  top: 90%;
  transform: translate(calc(100% + 8px), -50%);
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-right:after {
  border-width: 8px 8px 8px 0;
  border-color: transparent #15d9c1 transparent transparent;
  right: -8px;
  top: 50%;
  transform: translate(0, -50%);
}
.kpi-auto-container{

  display:flex;
  flex-direction: row;
  justify-content: space-around;
}
.kpi-auto-container > .kpi-list{
    width:50%;
    margin:20px;
}
.kpi-auto-container > .kpi-list > div > #picky[aria-expanded="true"]{
  z-index:1
}
.select-edges-tr > td{
  width:25%;
}
.select-edges-tr > td >  input {
  height:35px;
}
.causal_radio_btns > input {
  width:1.5em;
  height:1.5em
}
.causal_radio_btns > span,label {
 padding-top:5px;
}
.add-new-kpi-link:hover{
  cursor: pointer;
}