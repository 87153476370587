.hierarchy-value-container{
  display:flex;
  flex-direction: row;
  margin:20px;
}
.hierarchy-value-container > .hierarchy_list {
  flex: 1;
  margin-right:20px;
}
.hierarchy-value-container > .hierarchy_values {
  flex:1;
  margin-left:20px;
}
.hierarchy-value-container > .hierarchy_values > .picky[aria-expanded="true"]{
  z-index:1
}
.hierarchies_list > label,
.hierarchy_value_list > label {
  width: 15%;
}
.picky__dropdown {
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  /* z-index:0; */
}
input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  /* margin-top: 4px;
  margin-bottom: 8px; */
}
select,
textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 8px;
}
select {
  height: 35px;
}

.picky__input  {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
