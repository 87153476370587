ol.progtrckr {
    list-style-type: none;
    padding: 0;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
  }
  
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 2px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 2px solid #15D9C1;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 2px solid #15D9C1;
  }
  
  ol.progtrckr li:after {
    content: "\00a0\00a0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.9rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.5em;
    line-height: 1.5em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #15D9C1;
    border-radius: 1.5em;
    width: 1.5em;
    line-height: 1.5em;
  }
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #15D9C1;
    width: 1.5em;
    line-height: 1.5em;
    border-radius: 1.5em;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }
  
  .progtrckr {
    width: 100%;
    margin: 30px 0px;
  }
  
  ol.progtrckr li {
    line-height: 2.4rem;
  }
  ol.progtrckr li:before{
    bottom: -1.9rem;
  }
  ol.progtrckr li.progtrckr-todo:before{
    background: #EEEEEF;
  }