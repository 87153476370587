.resizer{
    display:inline-block;
    background:#00f;
    width:5px;
    height:100%;
    position:absolute;
    right:0;
    top:0;
    transform:translateX(50%);z-index:1
}
.ReactTable .rt-thead.-header{
    background-color:#15d9c1;
    padding: 10px 10px 10px 5px;
    color: #eeeeef;
    font-weight: bold;
    font-size: 14px;
  }
