* {
  box-sizing: border-box;
}
body {
  /* display: flex; */
  min-height: 100vh;
  flex-direction: row;
  margin: 0;
  font-size: 12px !important;
}

.col-2 {
  display: flex;
  flex-direction: column;
  flex: 5;
}
.content {
  display: flex;
  flex-direction: row;
}
.content > article {
  flex: 4;
  min-height: 80vh;
}


footer {
  border-top: 1px solid #a2a2a2;
  background-color: #f4f4f4;
}
.body a:hover {
  text-decoration: none;
}

.body a:focus {
  outline: transparent;
}
.btn {
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 10px;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 2;
  border-radius: 3px;
  min-width: 80px;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary {
  background-color: #008cba;
  /* Green */
}

.btn-success {
  background-color: #4caf50;
  /* Green */
}

.btn-alert {
  background-color: #f44336;
  /* Green */
}

.logo_nav {
  display: inline-block;
  width: 100%;
  padding: 20px 0px 0px 15px;
}
.logo_nav img{
   width:120px;
}
.logo_nav a {
  display: block;
  color: white;
  padding: 2px 0px 0px 2px;
}
header .title {
  display: flex;
  flex: 1 1;
  justify-content: center;
  flex-direction: column;
}

.fa .fa-remove {
  color: red;
}

#workflow {
  border-collapse: collapse;
  width: 100%;
  margin-top:2px;
}

#workflow td,
#workflow th {
  border: 1px solid #ddd;
  padding: 8px;
}

#workflow tr:nth-child(even) {
  background-color: #f2f2f2;
}

#workflow tr:hover {
  background-color: #ddd;
}

#workflow th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #15d9c1; /* #1B1B32; */
  color: white;
}

.delete-wf {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.add-btn {
  background-color: #008cba;
  float: right;
}
.button {
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.pipeline-form {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 0px;
  justify-content: center;
  padding: 100px;
}
.form-row {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}
.form-row > label {
  padding: 0.5em 0.1em 0.1em 0;
  width: 45%;
}
.form-row > input {
  border: 1px solid #ddd;
}
.form-row > input,
.form-row > button {
  padding: 0.7em;
}
.form-column > .text {
  text-align: center;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.4rem;
  outline: 0;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.7rem;
  background-color: #008cba;
  color: #fff;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-header .close {
  padding: 0.7rem;
  margin: -0.7rem -0.7rem -0.7rem auto;
  background-color: #008cba;
  outline: none;
  opacity: 0.5;
  outline-color: #008cba;
  border: none;
}
.modal-header .close:hover {
  cursor: pointer;
}
.modal-header .close .fa-close {
  font-size: 14px;
  color: #fff;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  border-top: 1px solid #d8d4d4;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.ReactModal__Overlay {
  background: transparent !important;
  transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content {
  transition: all 0.3s ease;
  border: none !important;
}

.breadcrumb_heading{
    display: flex;
    justify-content: space-between;
}

.breadcrumb_heading > label{
  padding: 20px 0px;
  font-size: 16px;
  font-weight: 600;
}
.navbar > .header-options > li.nav-item > a:hover{
  color:#008CBA;
  background-color:#eeeeee;
}
.navbar > .header-options > li.nav-item:hover > a{
  border: none !important;
  outline: none !important;
}
.m-10{
  margin:10px;
}
.pt-10{
  padding-top:10px !important;
}
.pt-15{
  padding-top:15px !important;
}
#data_frequency_value {
  width:90%;
}
.text-danger{
  color:red;
}
.ds-dropdown {
  margin-bottom:10px;
  width:30%;
}

/*captcha css */
.rnc {
  display: flex;
  flex-direction: row;
  background-color: #008cba26;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 0px 22px 0px 0px;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: 0 0 0px 0;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  width:146px;
}
 
.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.rnc-input {
  border: none;
  padding: 10px 6px;
  font-size: inherit;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #f2f4f5;
} 

button:disabled {
  background-color: #ddd !important;
}
button:disabled :hover {
  cursor:pointer;
}
.table-container table tbody tr td .delete .ds-tooltip {
 z-index:0 !important;
}
.table-container table tbody tr td .delete .ds-tooltip a.disabled {
  cursor: not-allowed;
  text-decoration: none;
  opacity:0.4;
  pointer-events: none;
}
.ReactTable .rt-tbody .rt-td .delete .ds-tooltip a.disabled{
  cursor: not-allowed;
  text-decoration: none;
  opacity:0.4;
  pointer-events: none;
}
.ReactTable .rt-tbody , .ReactTable .rt-tbody .rt-td:last-child {
  overflow:visible;
}
.ReactTable .rt-tbody .rt-td .causal-selection-delete-icon {
  white-space: normal;
}

.ReactTable .rt-tbody .rt-td .causal-selection-delete-icon .ds-tooltip .ds-tooltiptext {
  width:110px !important;
  margin-left: -56px !important;
}


.ds-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: none;
  z-index:999;
}
.ds-tooltip .ds-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #15d9c1;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
}

.ds-tooltip .ds-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 25px;
  border-width: 5px;
  border-style: solid;
  border-color: #15d9c1 transparent transparent transparent;
}

.ds-tooltip:hover .ds-tooltiptext {
  visibility: visible;
  opacity: 1;
  cursor: default;
}
.ds-linked-projects {
  display:flex;
  margin-top:10px;
}
.ds-linked-projects .anomaly-projects {
  width:50%;
  overflow: auto;
  min-height: 100px;
  max-height: 0px;
  margin-right:10px;
}
.ds-linked-projects .causal-projects {
  width:50%;
  overflow: auto;
  min-height: 100px;
  max-height: 0px;
}
.margin-auto {
  margin:auto !important;
}
.text-left{
  text-align: left !important;
}
.error-row{
  display: flex;
  justify-content: flex-start;
}
.error-row label{
  width:32%;
}
.error-row .error-msg{
  color:red;
  width:68%;
}
.text-center{
  text-align: center !important;
}
.change-report{
  width: 20%;
  float: right;
}
.radio_buttons{
  display: inline-flex;
  align-items: center;
}
.whatIfRadio{
  width: 1.2em;
  height: 1.2em;
}