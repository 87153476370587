.objectie-kpi-threshold , .alert-sum-params-container{
    display: flex;
    width:100%;
    justify-content:space-between
}
.objectie-kpi-threshold > .objectie-kpi,.threshold,.other-columns{
    width:32%;
}
.objectie-kpi , .other-columns{
    display:flex;
}
.objectie-kpi > label , .other-columns > label {
    width:40%;
    padding-top:8px;
}
.objectie-kpi > .objective-select , .other-columns > .objective-select{
    width:100%;
}
.threshold{
    display:flex;
}
.threshold > label{
    width:25%;
    padding-top:8px;
}
.source-kpi-container {
    max-height: 300px;
    margin-top:10px;
    min-height: 0px;
    overflow: auto;
}
.alert-sum-params-container .alert-sum-block{
    width:32%;
    display: flex;
    margin-top:15px;
    margin-right:6px;
}
.alert-sum-params-container .alert-sum-block label{
    width:35%;
}
.alert-sum-params-container .alert-sum-block .alert-sum-selection{
    width:60%;
}
.alert-sum-params-container .alert-sum-block .info-container{
    width:5%;
    margin-top:6px;
}
.alert-sum-params-container .alert-sum-block .sum-tooltip{
    z-index: 0 !important;
}

.alert-sum-fieldset {
    margin-top:20px;
    padding:2px 10px
}
.alert-sum-fieldset legend{
    padding:2px 20px
}
.add-row{
    width: 100%;
    text-align: right;
}
.add-row button{
    color:#0052CC;
    border:none;
    background-color: #fff;
}
.add-row button:disabled{
    color:#ccc !important;
    border:none !important;
    background-color: #fff !important;
}
.delete .disabled{
    cursor: not-allowed;
    text-decoration: none;
    opacity: 0.4;
    pointer-events: none;
}
.text-right{
    text-align:right;
    padding-right:10px;
}
