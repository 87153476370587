.hierarchy_sets_main{
width:20%;
border:1px solid #ddd;
}


/* upload section css */
.hierarchySet_flex-container{
    display: flex;
    background-color: #fff;
    padding:20px;
  }
  
 
  .hierarchySet_flex-container > .set_input{
    display: flex;
    justify-content: space-between;
    width: 35%;
  }
  
  
  .hierarchySet_flex-container > .set_input > label{
    line-height: 30px;
    width: 15%;
  }
  
  .hierarchySet_flex-container > .set_input > .create-set{
   width:55%;
  }
  .hierarchySet_flex-container > .set_input > .create-set > input{
    line-height:20px;
    margin-top:1px;
   }
 

  .hierarchySet_flex-container >  .set_input > .submit-btn{
    width:35%;
  }
  .hierarchySet_flex-container >  .set_input > .submit-btn > input{
    margin-top:0px;
    margin-left:2px;
  }

  .hierarchySet_Creation_flex-container {
    display: flex;
    background-color: #fff;
    padding:20px;
    min-height: 360px;
    max-height: 0px;
  }
  
  .hierarchySet_Creation_flex-container > .master_column_main   {
    background-color: #fff;
    width: 20%;
    margin: 10px;
  }

  .hierarchySet_Creation_flex-container > .set_creation_main   {
    background-color: #fff;
      max-width: 1000px; 
    display: flex;
     overflow: auto;
  }
  .hierarchySet_Creation_flex-container > .set_creation_main > div > .droppable-column:first-child {
    position: absolute;
    margin-left: -250px;
  }
  .hierarchySet_Creation_flex-container > .set_creation_main > div > .droppable-column {
    width:240px;
  }
  .hierarchySet_Creation_flex-container > .set_creation_main >  div > .droppable-column:first-child > h5 {
    background: #008cba;
    color: #fff;
    padding: 8px;
  }
  .hierarchySet_Creation_flex-container > .master_column_main >.master_column, .hierarchySet_Creation_flex-container > .set_creation_main > .set_creation{
    width: 100%;
    border: 1px solid #222222;
    min-height: 300px;
    max-height: 0px;
    overflow: auto;
    margin-top: 5px;
    background: lightcyan;
  }
  
  
  .hierarchy_flex-container > .master_column_main>h4,.hierarchy_flex-container > .time_column_main>  h4,.hierarchy_flex-container > .kpi_column_main>  h4,.hierarchy_flex-container > .hierarchy_column_main>  h4{
    text-align: center;
      margin: 5px;
  }
.set_col_dynamic{
    width: 100%;
    border: 1px solid #222222;
    min-height: 300px;
    max-height: 0px;
    overflow: auto;
    margin-top: 5px;
    background: lightcyan;
    display: block;
}
