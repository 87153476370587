.batch-upload-container{
    max-height:500px;
    min-height:300px;
    overflow: auto;
}
.batch-heading{
    padding:20px 0px;
}
.batch-upload-btns{
    display: flex;
    justify-content: flex-end;
}