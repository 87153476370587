.ds-info-details {
    display:block;
    min-height:200px;
    max-height:0px;
    padding:10px;
}
.ds-info-details .ds-info{
    display:flex;
    padding:4px;
}
.ds-info-details .ds-info .ds-label{
    width:20%;
    font-weight: bold;
}
.ds-info-details .ds-info .ds-colon{
    width:2%;
    font-weight: bold;
}
.ds-info-details .ds-info .ds-value{
    width:78%;
    max-height: 60px;
    min-height: 0px;
    overflow: auto;
}
