.table-container table {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22)
}

.table-container table tbody , .table-container table thead {
   border:1px solid #ddd;
  }

.table-container {
 overflow: auto;
 width: 100%;
 padding: 0;
 background-color: transparent;
}
.table-container table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-container table thead tr th.heading {
  padding: 0px;
  color: #eeeeef;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0,0,0,0.05);
  -webkit-transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
  transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.table-container table thead tr th .filter {
  padding:2px;
  width:100%;
  }
  .table-container table thead tr th .filter input{
    height: 22px;
    width:100%;
    font-size: 11px;
    }
.table-container table thead tr th .header-label {
  margin-bottom: 5px;
  padding: 8px;
  background:#15d9c1;
}
  
.table-container table thead tr th.filter input {
    width: 90%;
    height: 25px;
    font-size:11px;
    margin: 0px 2px;
}
.table-container table tbody tr {
  background-color: #ffffff;
}
.table-container table tbody tr td {
  padding: 10px;
  font-size: 12px;
}
.table-container table tbody tr td .view , 
.table-container table tbody tr td .delete ,
.table-container table tbody tr td .display_name {
    cursor: pointer;
}
.pagination {
    padding: 0.5rem;
}
.pagination > select{
    width: 15%;
    border: 1px solid #ccc;
    background: #fff;
}
