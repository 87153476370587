.notifications{
  position: relative;
}
.button__badge {
  background-color: #fa3e3e;
  border-radius: 25px;
  color: white;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
  top: 0;
  right: 0;
}