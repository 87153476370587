.upload-main {
  display: flex;
}

.upload-main > .upload-ip {
  flex: 1;
  order: 2;
}

.upload-main > .nav {
  flex: 0 0 20vw;
}

.upload-main > .nav {
  order: 1;
}

.data-selection {
  display: flex;
  flex-direction: column;
}

.button {
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.upload-btn {
  background-color: #008cba;
}

.flex-container {
  display: flex;
}

.flex-container > .filename {
  padding: 10px 15px 10px 0px;
  font-weight: 600;
  font-size: 14px;
}

.dropzone {
  border: 1px dotted dodgerblue;
  padding: 20px;
}

/* upload section css */

.parent{
  display:flex;
  flex-direction:row;
  width:100%

}
.parent .child1{
  width:23%;
  border:1px solid blue;
  height:30px;
}

.upload_flex_container {
  display: flex;
  background-color: #fff;
  align-items: center;
  margin: 20px 0px;
  justify-content: space-between;
}

.upload_flex_container > .upload_csv, .uploaded_csv {
  width:40%;
  background-color: #fff;
  color: #544b4b;
  margin: 0px;
  text-align: left;
  line-height: 2em;
  padding-top: 20px;
}
.upload_flex_container > .upload_csv > label{
  display: block;
}
.upload_flex_container > .upload_csv > input{
  width:80%;
}

.upload_flex_container > .upload_csv > .react-csv-input {
  padding: 0px !important;
}
.upload_flex_container > .config_upload{
  display:flex;
  width:25%;
}

.upload_flex_container > .frequency {
  width: 25%;
  justify-content: center;
}

.upload_flex_container > .window_main {
  width: 25%;
  justify-content: center;
}
.upload_flex_container > .window_main > .window > input {
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
}

.hierarchy_flex-container {
  display: flex;
  background-color: #fff;
}

.hierarchy_flex-container > .master_column_main,
.time_column_main,
.kpi_column_main,
.hierarchy_column_main {
  background-color: #fff;
  width: 20%;
  margin: 10px;
  font-size: 16px;
}

.hierarchy_flex-container > .master_column_main > .master_column {
  width: 100%;
  border: 1px solid #222222;
  min-height: 300px;
  max-height: 0px;
  overflow: auto;
  margin-top: 5px;
  background: lightcyan;
}

.hierarchy_flex-container > .time_column_main > .time_column,
.hierarchy_flex-container > .kpi_column_main > .kpi_column,
.hierarchy_flex-container > .hierarchy_column_main > .hierarchy_column {
  width: 100%;
  border: 1px solid #222222;
  min-height: 300px;
  max-height: 0px;
  overflow: auto;
  margin-top: 5px;
  background: #f1f1f1;
}

.hierarchy_flex-container > .master_column_main > h4,
.hierarchy_flex-container > .time_column_main > h4,
.hierarchy_flex-container > .kpi_column_main > h4,
.hierarchy_flex-container > .hierarchy_column_main > h4 {
  text-align: left;
  margin: 5px;
}

.hierarchy_flex-container > .two {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.hierarchy_flex-container > .three {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.hierarchy_flex-container > .four {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.hierarchy_flex-container > .five {
  background-color: DodgerBlue;
  color: white;
  width: 20%;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.done-kpi {
  background: #fff;
  height: 250px;
  width: 200px;
  margin: 10px;
  border: 1px solid #ddd;
}

.done-hierarchy {
  background: #fff;
  height: 250px;
  width: 200px;
  margin: 10px;
  border: 1px solid #ddd;
}

.kpi_section_main {
  width: 27%;
}
.kpi_section_main > .table_kpi_fixed_header {
  display:flex;
  flex-direction: column;
  width:100%
}
.kpi_section_main > .table_kpi_fixed_header > .header {
  width:100%;
  display: flex;
  font-weight: 500;
  padding: 0px 5px 15px 0px;

}
.kpi_section_main > .table_kpi_fixed_header > .header > .kpi {
  width:60%;
}
.kpi_section_main > .table_kpi_fixed_header > .header > .type {
  width:25%;
}
.kpi_section_main > .table_kpi_fixed_header > .header > .ratio {
  width:10%;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body  {
  max-height:330px;
  overflow: auto;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row {
  width:100%;
  display: flex;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_name {
  width:60%;
  word-break: break-word;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_type{
  width:25%;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_type > select {
  width:max-content;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_checkbox {
  width:15%;
  display: flex;
  justify-content: center;
}
.kpi_section_main > .table_kpi_fixed_header > .table_body > .custom_row > .kpi_checkbox > input{
  width:25px;
  height:20px;
}
.no-visibility {
  visibility: hidden;
}

.draggable_value {
  padding: 8px 0px;
  background: aqua;
  border: 1px solid #dddd;
  margin: 2px;
  columns: #222222;
}

/* TO-DO */
.hierarchy_flex_container {
  display: flex;
  max-height: 300px;
  background: white;

  max-width: 100%;
  /* overflow-x: scroll; */
}



.hide {
  display: none;
}
.show {
  display: block;
}

.file_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
.uploaded_f {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: row;
}
.h_files {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  overflow: auto;
  max-width: 70%;
}
/* .uploaded_f div:nth-child(even){background-color: #f2f2f2;} */

.uploaded_f div:hover {
  background-color: #ddd;
}

.f_name,
.d_operation {
  padding: 5px;
}

.change-uploadfile:hover {
  cursor: pointer;
}


.file_uploads_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 0px 10px 0px 0px;
}

.kpi_section_main {
  display: flex;
  justify-content: space-evenly;
}
.kpi_section_main > .ratio {
  margin-top: 0px;
}
.multiselect_container {
  display: flex;
  min-height: 400px;
  max-height: 0px;
}
.multiselect_container > .mulitiselect_panels {
  width: 24%;
  margin: 5px;
}

.upload_flex_container > .batch-heading > span {
  font-weight: 500;
  padding-left:8px;
}
.multiselect_container > .mulitiselect_panels > div > .picky > .picky__dropdown {
  z-index:0 !important;
}