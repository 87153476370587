.body {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  margin: 0;
}

.content {
  background-color: #ffffff;
}

#main {
  display: flex;
  min-height: calc(100vh - 10vh);
  flex: 1;
}

#main > article {
  flex: 1;
}

#main > nav {
  flex: 0 0 20vw;
}

#main > nav {
  order: -1;
}

header,
footer,
aside {
  padding: 1em;
}

article {
  padding: 0em 1em;
}
header {
  background: #eeeeef !important; 
  display: flex !important;
}


.main-nav {
  margin-top: 22px;
  padding-left: 0px;
}

.logo a,
.main-nav a {
  padding: 10px 15px;
  text-transform: uppercase;
  display: block;
}

.nav {
  padding: 0px;
  text-decoration: none;
}

.logo a {
  color: white;
  padding: 2px 0px 0px 2px;
}
.logo a img {
  /* width:100%; */
  /* height:60px; */
}

.main-nav a {
  color: #8d939d;
  font-size: 0.99em;
}

.main-nav a:hover {
  color: #60dac2;
}

.main-nav .active {
  color: #60dac2;
  background: #0c1228;
  border-left: 3px solid #15d9c1;
}

header {
  /* padding-top: .5em;
    padding-bottom: .5em; */
  background-color: white;
}

.notification {
  background: grey;
  color: black;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.analyst-view,
.train-dt,
.train-ctrl {
  display: flex;
}

.analyst-view {
  flex-direction: row;
  justify-content: center;
}

.train-dt {
  flex-direction: column;
}

.train-ctrl {
  flex-direction: column;
  width: 100%;
}

.perf-panel {
  flex-direction: column;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2,
h3 {
  color: #34495e;
}

a {
  text-decoration: none;
}

.logo {
  margin: 0;
  font-size: 1.3em;
  padding: 0px 2px;
  width: 100%;
}

.set_col_dynamic {
  width: 100%;
  border: 1px solid #222222;
  min-height: 300px;
  max-height: 0px;
  overflow: auto;
  margin: 10px;
  background: lightcyan;
  display: flex;
}

header .title {
  display: flex;
  flex: 1 1;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.logo {
  display: inline-block;
}

.footer-content {
  width: 100%;
  display: flex !important;
}
.footer-content p{

  width: 100%;
  text-align: center;
  line-height: 2em;

}
.footer-content p .footer-terms-btn{
  color: #0043de;

  border: 0;
  background: rgba(0,0,0,0);
  cursor: pointer;
  font-size: 12px;
  letter-spacing: -0.12px;
}
.nav-links {
  display: flex;
}

.nav-item a {
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
}

.nav-item {
  margin-top: 0px;
}

.col-1 {
  background: #1b283b;
  flex: 1;
}

.btn-next,
.btn-prev {
  padding: 6px 12px;
  margin: 0px 10px;
  font-size: 12px;
}

.footer-buttons {
  margin: 14px 0px;
}

.node rect {
  stroke: #333;
  fill: #fff;
}

.edgePath path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

ol {
  display: flex;
  justify-content: space-between;
}

ol li {
  display: block;
  width: 100%;
  flex: auto;
  list-style-type: none;
}
ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #15d9c1;
  border-radius: 1.5em;
  width: 1.5em;
  line-height: 1.5em;
}

.pipeline-ul {
  padding-left: 0px;
}
