/* @import url('https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css');*/

@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
.layout {
  background: rgba(58, 63, 68, 0.5);
  border-radius: 5px;
  box-shadow: 0 1.5px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

/* 
body {
  display: flex;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  color: #fff;
  background: #222222;
  background: #16222A;
  background: -webkit-linear-gradient(to top, #16222A, #3A6073);
  background: linear-gradient(to top, #16222A, #3A6073);
}
 */

.chart {
  display: flex;
  flex: 0 1 50%;
  justify-content: center;
  align-items: center;
  background: #2BBDCA;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.login-form {
  background: #162845;
  border-radius: 5px;
  box-shadow: 0 1.5px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex: 0 1 50%;
  min-height: 100vh;
  text-align: center;
  color: #FFFFFF;
  min-height: 100vh;
  justify-content: center;
}

.login-form .acc-logo{
    padding:10px;
}
.login-form .acc-logo img{
  width:120px;
}
#title-heading{
  font-size: 1.5rem !important;
}
.login-form .ms-logo{
  margin-top:25px;
}
.login-form .ms-logo img{
  width:150px;
}

.wt-text {
  display: flex;
  width: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  min-height: 60vh;
  justify-content: center;
  /* align-items: center; */
}

.wt-text {
  background: url(./assets/images/wt-bg.png) 97% 50% no-repeat;
  mix-blend-mode: screen;
}

.logo {
 
  text-align: center;
 
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 1.3em;
  padding: 0px 2px;
  width: 100%;

}

.login-item {
  color: #ffff;
  padding: 25px 25px 0;
  margin: 20px 20px 0;
  border-radius: 3px;
}



.form input[type="password"], .form input[type="text"], .form input[type="submit"] {
  width: 100%;
}

.form-login label, .form-login input[type="text"], .form-login input[type="password"], .form-login input[type="submit"] {
  border-radius: 0.25rem;
  padding: 1rem 0.5rem;
  color: #3A3F44;
}

.form-login input[type="text"], .form-login input[type="password"] {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color:#fff !important;
}

.form-login input[type="text"]:focus, .form-login input[type="text"]:hover, .form-login input[type="password"]:focus, .form-login input[type="password"]:hover {
  background-color: #eeeeee;
  color:#fff !important;
}

.form-login input[type="submit"] {
  background-color: #00B9BC;
  color: #eee;
  font-weight: bold;
  text-transform: uppercase;
}

.form-login input[type="submit"]:focus, .form-login input[type="submit"]:hover {
  background-color: #197071;
  color:#fff !important;
}
.login-form-field{
  margin-bottom: 2rem;
}

.form-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text--center {
  text-align: center;
}

.form-input {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #4E5A6C !important;
  color: white;
}
.login-btn{
  border: 3px solid #2BBDCA  !important;
  background: transparent !important;
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.8);
  width: 130px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 32px;
    height: 32px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.has-error .help-block {
  color:red;
  text-align: left;
}