
  .data-source-select{
      width:100%;
  }
  .menu-outer-top .Select-menu-outer {
    bottom: 35px!important;
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-top-right-radius: 4px!important;
    border-top-left-radius: 4px!important;
}