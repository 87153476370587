.line {
  fill: none;
  stroke: #ffab00;
  stroke-width: 2;
}

.dot {
  fill: #ffab00;
  stroke: #fff;
}

.primary {
  fill: none;
  stroke: navy;
}

.upper {
  fill: none;
  stroke: grey;
}

.lower {
  fill: none;
  stroke: grey;
}

div.tooltip-anomaly {
  position: absolute;
  background-color: white;
  max-width: 200px;
  height: auto;
  padding: 1px;
  border-style: solid;
  border: 1px solid #846c6c;
  border-radius: 6px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 1001;
}

.a-chart-wrapper {
  width: 100%;
  height: 400px !important;
}
