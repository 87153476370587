.causal_flex_container {
  display: flex;
  margin-bottom:30px;
  flex: 0 1;
}

.causal_flex_container > .hierarchy_file_selection {
  width:40%;
}
.causal_flex_container > .hierarchy_file_selection> select {
  height:30px;
  width:100%;
}
.causal_flex_container > .hierarchy_file_values {
  width:25%;
  margin-left:15px;
}
.causal_flex_container > .hierarchy_filter_values {
  width:25%;
  margin-left:15px;
}

.causal_flex_container > .hierarchy_file_values>select {
 height:30px;
 width:100%;
}

/* tbody {
    border:1px solid #ddd;
} */

.causal-run-btn{
  text-align:right;
  display: flex;
  justify-content: space-between;
  margin-top:20px;
}
.causal-run-btn > .add{
  display: flex;
}
input,textarea {
  border:1px solid #ccc
}
.hide{
  display: none;
}
.causal_radio_btns{
  display: flex;
}
.causal-project-heading_container{
  display: flex;
    justify-content: space-between;
    margin-top: 0px;
  
}
.causal_exp_heading {
  margin-top:10px;
}
.causal-project-heading{

    padding: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 50px;
}
.causal_runs_container > #workflow{
  margin-top:0px ;
}