.datamap-container {
    max-height: 250px;
    overflow: auto;
}

.over_all_causal {
    display: flex;
    flex-direction: column;
    padding:20px 0px;
}

.causal_upload {
    /* display: flex; */
}

.causal_flex_container {
    display: flex;
    padding:20px 0px;
}

.hierarchy_file_selection, .causal_file, .hierarchy_filter_values {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
}
.causal_file{
    padding: 0px 5px;
}
.causal_relation_heading{
    padding-top:20px;
}
.causal_upload_hier{
    width:100%;
    margin-top:20px;
}
.causal_upload_hier>.hierarchy_list>label,.causal_upload_hier>.hierarchy_values>label{
    width:25%;
    padding-top:10px;
}
.causal_upload_hier > .hierarchy_list > select , .causal_upload_hier>.hierarchy_values > .picky {
    width:60%;
}
.causal_upload{
    width:60%;
    margin:20px;

}
.causal_upload>label{
    width:25%;
    padding-top:3px;
}
.causal_upload>input{
    width:60%;
}
.add_causal_btn{
    width:60%;
    text-align: right;
}
.causal_upload_hier > .hierarchy_list > .hier_select {
    width:60%;
}
.causal_save_add_btns{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 15px;
}
.upload_causal{
    display:flex;
    justify-content: flex-end;
}